import React, { useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { UserContext } from "../../context/UserContext";

const RecruiterForm = () => {
  const { handleFormSubmit } =
    useContext(UserContext);

  // Validation schema using Yup
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    empId: Yup.string().required("Employee ID is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    userType: Yup.string().required("User Type is required"),
  });

  // Initial values for the form
  const initialValues = {
    name: "",
    empId: "",
    email: "",
    userType: "",
  };

  // Function to handle form submission
  const onSubmit = (values, { resetForm }) => {
    // console.log("Form data", values);
    handleFormSubmit(values);
    resetForm();

  };

  return (
    <div className="flex items-center justify-center w-full h-screen ">
      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-col gap-4 w-1/2 p-8  rounded-lg shadow-md">
            <div className="flex flex-col text-white ">
              <label htmlFor="name" className="mb-2  font-semibold text-white">
                Name
              </label>
              <Field
                type="text"
                id="name"
                name="name"
                className="p-2 border rounded-lg  bg-white text-black focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <ErrorMessage
                name="name"
                component="div"
                className="mt-1 text-red-500"
              />
            </div>

            <div className="flex flex-col text-white ">
              <label htmlFor="empId" className="mb-2 text-white font-semibold">
                EmpId
              </label>
              <Field
                type="text"
                id="empId"
                name="empId"
                className="p-2 border rounded-lg bg-white text-black focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <ErrorMessage
                name="empId"
                component="div"
                className="mt-1 text-red-500"
              />
            </div>

            <div className="flex flex-col text-black">
              <label htmlFor="email" className="mb-2 text-white font-semibold">
                Email
              </label>
              <Field
                type="email"
                id="email"
                name="email"
                className="p-2 border  bg-white text-black rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="mt-1 text-red-500"
              />
            </div>

            <div className="flex flex-col text-black">
              <label
                htmlFor="userType"
                className="mb-2  text-white font-semibold"
              >
                User Type
              </label>
              <Field
                as="select"
                id="userType"
                name="userType"
                className="p-2 border rounded-lg bg-white text-black focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select Type</option>
                <option value="Admin">Admin</option>
                <option value="HR Manager">HR Manager</option>
                <option value="Business Developer">Business Developer</option>
                <option value="Recruiter">Recruiter</option>
              </Field>
              <ErrorMessage
                name="userType"
                component="div"
                className="mt-1 text-red-500"
              />
            </div>

            <button
              type="submit"
              className="p-3 mt-4 text-white shadow-[0_35px_60px_-15px_rgba(0,0,0,10)] cursor-pointer bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RecruiterForm;
