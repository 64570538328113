import React, { Fragment, useContext, useEffect, useState } from "react";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";
import styles from "../../Styles";
import { RecruimentHeader } from "../component/RecruimentHeader";
import { RecruitmentSubHeader } from "../component/RecruitmentSubHeader";
import Header from "../../admin/components/Header";
import { useAuth } from "../../context/AuthContext";
import { usePipeLine } from "../../context/PipelineContext";
import { TableContainer, useDisclosure } from "@chakra-ui/react";
import { RecordOfferContext } from "../../context/RecordOfferContext";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import AdminSubheader from "../../admin/components/AdminSubheader";
import PendingOfferPopover from "../component/popover/PendingOfferPopover";
import AcceptOfferPopover from "../component/popover/AcceptOfferPopover";
import { formatDate } from "../../Utils/ExtraFun";

export const Offers = () => {
  const { userData } = useAuth();
  const { handleApplicationForm, fetchedData } = usePipeLine();
  const [filteredOfferData, setFilteredOfferData] = useState([]);
  const { handleRecordResOffer } = useContext(RecordOfferContext);
  const [appId, setAppId] = useState("");
  const { onClose, onOpen, isOpen } = useDisclosure();

  const validationSchema = Yup.object({
    offerStatus: Yup.string().required("Offer Status is required"),
    remarks: Yup.string().required("Remarks are required"),
  });

  useEffect(() => {
    if (userData.token) {
      handleApplicationForm();
    }
  }, [userData.token]);

  useEffect(() => {
    const filterOffer = fetchedData?.filter(
      (user) =>
        user.offerDetails &&
        Object.keys(user.offerDetails).length > 1 &&
        user.applicationStatus !== "Rejected" &&
        user.applicationStatus !== "Not Started"
    );
    console.log("Filter Fetch data:", fetchedData);
    console.log("Filter offer:", filterOffer);
    setFilteredOfferData(filterOffer);
  }, [fetchedData]);

  const handleRecordOfferResponeModal = async (values, appId) => {
    await handleRecordResOffer(values, appId);
  };

  return (
    <Fragment>
      {userData.userType === "Recruiter" ? <RecruimentHeader /> : <Header />}
      <AdminSubheader />
      <div className={` bg-black overflow-hidden w-full  h-[1280px] pipeline`}>
        <div className={`${styles.paddingX} ${styles.flexCenter} z-[3]`}>
          <div className={`${styles.boxWidth}`}>
            <h1 className="font-poppins text-blue-500 text-4xl shadow-lg shadow-blue-500 mt-3 text-center">
              Offers
            </h1>

            <div
              className={`text-white font-poppins text-[16px] border-2 mt-4  max-h-[80vh] bg-blue-900  shadow-lg shadow-blue-500  items-center  ${styles.boxWidth}`}
              style={{
                scrollbarWidth: "thin",
              }}
            >
              {/*firefox scrollbar styling*/}
              <style>
                {`.text-white::-webkit-scrollbar{
                  width:12px;}
                  .text-white::-webkit-scrollbar-thumb{
                  background-color:blue;
                  border-radius:10px;
                  }
                  .text-white::-webkit-scrollbar-track{
                  background:#ffffff00}`}
              </style>
              <TableContainer
                style={{
                  scrollbarWidth: "thin",
                }}
              >
                <table className=" w-full text-center z-[1]" id="table-to-xls">
                  <thead className="border-b">
                    <tr>
                      <th className="border">SL.No</th>
                      <th className="border">Application Id</th>
                      <th className="border">Application Status</th>
                      <th className="border">Offer Id</th>
                      <th className="border">Job Id</th>
                      <th className="border">Emp Id</th>
                      <th className="border">Student Id</th>
                      <th className="border">New Offered Salary</th>
                      <th className="border">Fixed Component</th>
                      <th className="border">Variable Component</th>
                      <th className="border">Offer Status</th>
                      {/* <th>Previous Offered Salary</th> */}
                      <th className="border">Offered On</th>
                      <th className="border">Created Date</th>
                      <th className="border">More</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredOfferData?.map((item, index) => (
                      <tr className="text-center" key={index}>
                        <td>{index + 1}</td>
                        <td>{item.applicationID}</td>
                        <td>{item.applicationStatus}</td>
                        <td>{item.offerDetails.offerId}</td>
                        <td>{item.jobDescription.jobId}</td>
                        <td>{item.recruiter.empId}</td>
                        <td>{item.candidate.stuId}</td>
                        <td>{item.offerDetails.newOfferedSalary}</td>
                        <td>{item.offerDetails.fixedComponent}</td>
                        <td>{item.offerDetails.variableComponent}</td>
                        <td>{item.offerDetails.offerStatus}</td>
                        {/* <td>{item.offerDetails.previousOfferedSalary}</td> */}
                        <td>{formatDate(item.offerDetails.offeredOn)}</td>
                        <td>{formatDate(item?.createdAt)}</td>
                        <td className="h-20 w-44 max-w-11 max-h-20 ">
                          {item.offerDetails.offerStatus === "Pending" ? (
                            <PendingOfferPopover
                              validationSchema={validationSchema}
                              setAppId={setAppId}
                              item={item}
                              handleRecordOfferResponeModal={
                                handleRecordOfferResponeModal
                              }
                              appId={appId}
                            />
                          ) : (
                            <textarea
                              readOnly
                              value={item?.offerDetails?.remarks}
                              className=" text-black"
                            >
                              {/* {item?.offerDetails?.offerStatus} */}
                            </textarea>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TableContainer>
            </div>
          </div>
        </div>
        <div className="relative z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
        <div className="relative z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-10" />
        <div className="relative z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
      </div>
    </Fragment>
  );
};
