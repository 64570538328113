import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import RecordCvPop from "../popover/RecordCvPop";
import { PipelineContext } from "../../../context/PipelineContext";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { RejectApplicationContext } from "../../../context/RejectApplicationContext";

const PipelineMenu = ({ item }) => {
  console.log("recrd offfer menu");
  const { forwardCv, recordCvRes } = useContext(PipelineContext);
  const {
    isOpen: isRejectOpen,
    onOpen: onRejectOpen,
    onClose: onRejectClose,
  } = useDisclosure();

  const {
    isOpen: isRecordOpen,
    onOpen: onRecordOpen,
    onClose: onRecordClose,
  } = useDisclosure();
  const { handleRejectApplication } = useContext(RejectApplicationContext);

  //validation Schema for record placement using Formik
  const validationSchema = Yup.object().shape({
    rejectionRemarks: Yup.string().required("Rejection Remarks is Required"),
  });

  const recordValidationSchema = Yup.object().shape({
    result: Yup.string().required("Result is Required"),
    remark: Yup.string().required("Remark is Reqired"),
  });

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
          variant={"outline"}
          color={"black"}
          bg={"white"}
        />
        <MenuList>
          {item?.cvProcess === "Completed" ? (
            ""
          ) : item?.cvProcess === "Not Started" ? (
            <MenuItem
              color={"black"}
              onClick={() => {
                console.log("applicationID", item?._id);
                forwardCv(item?._id);
              }}
            >
              Forward CV
            </MenuItem>
          ) : (
            <MenuItem color={"black"} onClick={onRecordOpen}>
              Record CV
            </MenuItem>
          )}

          <MenuItem onClick={onRejectOpen} color={"black"}>
            Reject Application
          </MenuItem>
        </MenuList>
      </Menu>

      {/* Modal for Reject Application */}

      <Modal isOpen={isRejectOpen} onClose={onRejectClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reject Application</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                rejectionRemarks: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                console.log("values:", values);
                console.log("application id:", item._id);
                handleRejectApplication(values, item._id);
                onRejectClose();
              }}
            >
              {({ errors, touched }) => (
                <Form className="flex flex-col gap-3">
                  <label htmlFor="remarks" className="font-serif font-medium">
                    Rejection Remarks:
                  </label>
                  <Field
                    as="textarea"
                    className="bg-gray-200 font-serif font-medium rounded-md outline-none p-4"
                    name="rejectionRemarks"
                    id="remarks"
                  />
                  {errors.rejectionRemarks && touched.rejectionRemarks ? (
                    <div className="text-red-500">
                      {errors.rejectionRemarks}
                    </div>
                  ) : null}

                  <Button type="submit" colorScheme="blue" mt={4}>
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onRejectClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for Record Cv Res ApplicationI*/}

      <Modal isOpen={isRecordOpen} onClose={onRecordClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Record Response</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                result: "",
                remark: "",
              }}
              validationSchema={recordValidationSchema}
              onSubmit={(values) => {
                console.log("values:", values);
                console.log("application Id:", item._id);
                recordCvRes(item._id, values);
                onRecordClose();
              }}
            >
              {({ errors, touched }) => (
                <Form className="flex flex-col gap-3">
                  <label htmlFor="result" className="font-medium font-serif">
                    Result
                  </label>
                  <Field
                    as="select"
                    className="bg-gray-200 font-serif font-medium rounded-md outline-none p-4"
                    id="result"
                    name="result"
                  >
                    <option value="">Choose one</option>
                    <option value="Selected">Selected</option>
                    <option value="Rejected">Rejected</option>
                  </Field>
                  {errors.result && touched.result ? (
                    <div className="text-red-500">{errors.result}</div>
                  ) : null}
                  <label htmlFor="remark" className="font-medium font-serif">
                    Remark:
                  </label>
                  <Field
                    as="textarea"
                    className="bg-gray-200 font-serif font-medium rounded-md outline-none p-4"
                    id="remark"
                    name="remark"
                  />
                  {errors.remark && touched.remark ? (
                    <div className="text-red-500">{errors.remark}</div>
                  ) : null}
                  <Button type="submit" colorScheme="blue" mt={4}>
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onRecordClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PipelineMenu;
