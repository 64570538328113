import React, { createContext, useState } from "react";
import { useEnv } from "./EnvContext";
import axios from "axios";
import toast from "react-hot-toast";
import { useAuth } from "./AuthContext";

// Create the context
export const UserContext = createContext();

// Create a provider component
export const UserContextProvider = ({ children }) => {
  const { backendUrl } = useEnv();
  const { userData } = useAuth();



  const handleFormSubmit = async (formValues) => {
    console.log("formValues", formValues);
    const token = userData.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading("Submit in progress....", {
        position: "top-right",
        style: {
          marginTop: "62px",
        },
      });
      const res = await axios.post(`${backendUrl}/user/add_user`, formValues, {
        headers,
      });
      console.log("res", res);
      toast.success(res.data.message, {
        position: "top-right",
        style: {
          marginTop: "62px",
        },
      });
    } catch (error) {
      console.log(error);
      const errorMessage =
        error.response?.data?.message || "Something went wrong";
      toast.error(errorMessage, {
        position: "top-right",
        style: {
          marginTop: "62px",
        },
      });
    } finally {
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  return (
    <UserContext.Provider
      value={{ handleFormSubmit }}
    >
      {children}
    </UserContext.Provider>
  );
};
