import axios, { Axios } from "axios";
import React, { createContext } from "react";
import toast from "react-hot-toast";
import { useEnv } from "./EnvContext";
import { useAuth } from "./AuthContext";
import { values } from "lodash";

export const ApplicationContext = createContext();
const ApplicationContextProvider = ({ children }) => {
  const { backendUrl } = useEnv();
  const { userData } = useAuth();

  const handleApplicationForwardCv = async (applicationId) => {
    const token = userData?.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let loadingToast;
    try {
      loadingToast = toast.loading("forward cv..", {
        position: "top-right",
        style: {
          marginTop: "65px",
        },
      });
      const res = await axios.put(
        `${backendUrl}/application/forward_cv?applicationId=${applicationId}`,
        null,
        { headers }
      );
      const data = res.data;
      console.log(data);
      toast.success(data.message, {
        position: "top-right",
        style: {
          marginTop: "62px",
        },
      });
    } catch (err) {
      console.log(err);
      console.log(err.response);
      if (err.response.data.message) {
        toast.error(`${err.response.data.message}`, {
          position: "top-right",
          style: {
            marginTop: "67px",
          },
        });
      } else {
        toast.error("Something went wrong", {
          position: "top-right",
          style: {
            marginTop: "65px",
          },
        });
      }
    } finally {
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const handleRecordCv = async (applicationId, values) => {
    const token = userData?.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading("Record Cv...", {
        position: "top-right",
        style: {
          marginTop: "65px",
        },
      });
      console.log(backendUrl);
      const res = await axios.put(
        `${backendUrl}/application/record_cv_res?applicationId=${applicationId}`,
        values,
        { headers }
      );
      console.log(res);
      const data = res.data;
      console.log(data);
      toast.success(data.message, {
        position: "top-right",
        style: {
          marginTop: "65px",
        },
      });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      if (error.response.data.message) {
        toast.error(`${error.response.data.message}`, {
          position: "top-right",
          style: {
            marginTop: "65px",
          },
        });
      } else {
        toast.error("Something went wrong", {
          position: "top-right",
          style: {
            marginTop: "67px",
          },
        });
      }
    } finally {
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const handleScheduleInterview = async (applicationId, values) => {
    const token = userData?.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading("Submit interview schedule....", {
        position: "top-right",
        style: {
          marginTop: "80px",
        },
      });
      let res = await axios.put(
        `${backendUrl}/application/schedule_interview?applicationId=${applicationId}`,
        values,
        { headers }
      );
      console.log(res);
      let data = res.data;
      console.log(data);

      toast.success(data.message, {
        position: "top-right",
        style: {
          marginTop: "64px",
        },
      });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      if (error.response.data.message) {
        toast.error(`${error.response.data.message}`, {
          position: "top-right",
          style: {
            marginTop: "65px",
          },
        });
      } else {
        toast.error("Something went wrong", {
          position: "top-right",
          style: {
            marginTop: "68px",
          },
        });
      }
    } finally {
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  return (
    <ApplicationContext.Provider
      value={{
        handleApplicationForwardCv,
        handleRecordCv,
        handleScheduleInterview,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationContextProvider;
