import React, { createContext, useCallback, useMemo } from "react";
import { useEnv } from "./EnvContext";
import { useAuth } from "./AuthContext";
import toast from "react-hot-toast";
import axios from "axios";

export const RevertApplicationContext = createContext();
const RevertApplicationContextProvider = ({ children }) => {
  const { backendUrl } = useEnv();
  const { userData } = useAuth();

  const handleRevertApplication = useCallback(
    async (applicationId) => {
      const token = userData?.token;

      if (!token) {
        toast.error("User not authenticated", {
          position: "top-right",
          style: {
            marginTop: "80px",
          },
        });
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      let loadingtoast;
      try {
        loadingtoast = toast.loading("Revert Application...", {
          position: "top-right",
          style: {
            marginTop: "68px",
          },
        });
        const response = await axios.put(
          `${backendUrl}/application/revert_rejection?applicationId=${applicationId}`,
          { headers }
        );
        console.log("revert response:", response);
        const data = response.data;
        console.log("revert response data:", data);

        toast.success(data.message, {
          position: "top-right",
          style: {
            marginTop: "60px",
          },
        });
      } catch (error) {
        console.log("revert error message:", error);
        console.log("Revert error response.data", error.response);
        if (error.response.data.message) {
          toast.error(`${error.response.data.message}`, {
            position: "top-right",
            style: {
              marginTop: "70px",
            },
          });
        } else {
          toast.error("Something Went wrong", {
            position: "top-right",
            style: {
              marginTop: "70px",
            },
          });
        }
      } finally {
        if (loadingtoast) {
          toast.dismiss(loadingtoast);
        }
      }
    },
    [backendUrl, userData?.token]
  );

  const contextValue = useMemo(
    () => ({
      handleRevertApplication,
    }),
    [handleRevertApplication]
  );
  return (
    <RevertApplicationContext.Provider value={contextValue}>
      {children}
    </RevertApplicationContext.Provider>
  );
};

export default RevertApplicationContextProvider;
