import React, { createContext, useState } from "react";
import { useAuth } from "./AuthContext";
import toast from "react-hot-toast";
import axios from "axios";
import { useEnv } from "./EnvContext";

export const DeletedEmployeeContext = createContext();

const DeletedEmployeeProvider = ({ children }) => {
  const { userData } = useAuth();
  const { backendUrl } = useEnv();
  const [delUsers, setDelUsers] = useState([]);

  const deletedData = async () => {
    const token = userData.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let loadingToast;
    try {
      loadingToast = toast.loading("Fetching Data....", {
        position: "top-right",
        style: {
          marginTop: "62px",
        },
      });

      const res = await axios.get(`${backendUrl}/user/deleted_user`, {
        headers,
      });
      console.log(res);
      const data = res.data.users;
      console.log(data);
      toast.success(res.data.message, {
        position: "top-right",
        style: {
          marginTop: "62px",
        },
      });
      setDelUsers(data);
    } catch (error) {
      console.log(error);
      const errorMessage =
        error.response?.data?.message || "Something Went Wrong";
      toast.error(errorMessage, {
        position: "top-right",
        style: {
          marginTop: "65px",
        },
      });
      setDelUsers([]);
    } finally {
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  return (
    <DeletedEmployeeContext.Provider value={{ deletedData, delUsers }}>
      {children}
    </DeletedEmployeeContext.Provider>
  );
};

export default DeletedEmployeeProvider;
