import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ChakraProvider,
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Button,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import EnvContext from "../../context/EnvContext";
import axios from "axios";
import { EmployeeContext } from "../../context/EmployeeContext";
import { useAuth } from "../../context/AuthContext";

export const UpdateEmpModal = ({ children, userId, employeeId, usertype }) => {
  const { userData } = useAuth();
  const { backendUrl } = useContext(EnvContext);
  const { getNonDeletedData } = useContext(EmployeeContext);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [empId, setEmpId] = useState(employeeId || "");
  const [userType, setUserType] = useState(usertype || "");
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      empId: empId,
      userType: userType,
    };
    const token = userData.token;

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await axios.put(
        `${backendUrl}/user/update_user_credentials?userId=${userId}`,
        postData,
        {
          headers,
        }
      );
      toast({
        title: "User credentials updated.",
        description: "The user credentials have been successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
      getNonDeletedData();
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: "Unable to update user credentials.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal
        isCentered
        isOpen={isOpen}
        size={"xl"}
        scrollBehavior={"inside"}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box maxW="sm" mx="auto" mt={5}>
              <form onSubmit={handleSubmit}>
                <FormControl id="empId" isRequired>
                  <FormLabel>Employee ID</FormLabel>
                  <Input
                    type="text"
                    value={empId}
                    onChange={(e) => setEmpId(e.target.value)}
                    placeholder="Enter employee ID"
                  />
                </FormControl>

                <FormControl id="userType" mt={4} isRequired>
                  <FormLabel>User Type</FormLabel>
                  <Select
                    value={userType}
                    onChange={(e) => setUserType(e.target.value)}
                  >
                    <option value="Admin">Admin</option>
                    <option value="HR Manager">HR Manager</option>
                    <option value="Business Developer">
                      Business Developer
                    </option>
                    <option value="Recruiter">Recruiter</option>
                  </Select>
                </FormControl>

                <Button mt={4} colorScheme="teal" type="submit">
                  Update Credentials
                </Button>
              </form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
