import {
  Button,
  FormControl,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";

const PendingOfferPopover = ({
  validationSchema,
  setAppId,
  item,
  handleRecordOfferResponeModal,
  appId,
}) => {
  return (
    <div>
      <Popover>
        <PopoverTrigger>
          <Button
            colorScheme="teal"
            size={"sm"}
            onClick={() => setAppId(item._id)}
          >
            Record Offer Response
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent h={"14rem"}>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <Formik
                initialValues={{
                  offerStatus: "",
                  remarks: "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  await handleRecordOfferResponeModal(values, appId);
                  setSubmitting(false);
                  onclose(); //close the popover after submission
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <FormControl isRequired>
                      <div>
                        <label className="text-lg" htmlFor="offerStatus">
                          Offer Status:
                        </label>
                        <Field
                          as="select"
                          id="offerStatus"
                          name="offerStatus"
                          className="w-40 text-center text-base"
                        >
                          <option value="">Select</option>
                          <option value="">Chose One</option>

                          <option value="Accepted">Accepted</option>
                          <option value="Rejected">Rejected</option>
                        </Field>
                        <ErrorMessage
                          name="offerStatus"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                      <div className="flex mt-3 gap-5">
                        <label className="text-lg" htmlFor="remarks">
                          Remarks:
                        </label>
                        <Field
                          as="textarea"
                          placeholder="type here remarks"
                          name="remarks"
                          id="remarks"
                          className="p-3 border"
                        />
                        <ErrorMessage
                          name="remarks"
                          component="div"
                          className="text-red-500"
                        />
                      </div>

                      <Button
                        mt={8}
                        colorScheme="teal"
                        type="submit"
                        isLoading={isSubmitting}
                      >
                        Submit
                      </Button>
                    </FormControl>
                  </Form>
                )}
              </Formik>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </div>
  );
};

export default PendingOfferPopover;
