import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { PlacementContex } from "../../../context/PlacementContex";

const PopoverPlacement = ({ item }) => {
  const { handleRecordPlacement, handleRecordPlacementUpdate } =
    useContext(PlacementContex);
  const [applicationId, setApplicationId] = useState();

  const {
    isOpen: isPlacementOpen,
    onOpen: onPlacementOpen,
    onClose: onPlacementClose,
  } = useDisclosure();

  const {
    isOpen: isUpdateOpen,
    onOpen: onUpdateOpen,
    onClose: onUpdateClose,
  } = useDisclosure();

  // Validation Schema for record placement using Formik
  const validationSchema = Yup.object().shape({
    joiningDate: Yup.date().required("Joining Date is required"),
    noticePeriodStartDate: Yup.date().required(
      "Notice Period Starting Date is required"
    ),
    noticePeriodEndDate: Yup.date().required(
      "Notice Period End Date is required"
    ),
  });

  //Validation Scehma for Record Placement Update using Formik
  const updateValidationSchema = Yup.object().shape({
    remarks: Yup.string().required("Remarks are Required"),
    isJoined: Yup.string().required("joining status is required"),
  });

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
          variant={"outline"}
          color={"black"}
          bg={"white"}
          onClick={() => setApplicationId(item._id)}
        />
        <MenuList>
          <MenuItem onClick={onPlacementOpen} color={"black"}>
            Record Placement
          </MenuItem>
          <MenuItem onClick={onUpdateOpen} color={"black"}>
            Record Placement Update
          </MenuItem>
        </MenuList>
      </Menu>

      {/* Modal for Record Placement */}
      <Modal isOpen={isPlacementOpen} onClose={onPlacementClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Record Placement</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                joiningDate: "",
                noticePeriodStartDate: "",
                noticePeriodEndDate: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                console.log("Values", values);
                handleRecordPlacement(values, applicationId);
                onPlacementClose();
              }}
            >
              {({ errors, touched }) => (
                <Form className="flex gap-3 font-serif flex-col">
                  <label htmlFor="joiningDate">Joining Date:</label>
                  <Field
                    type="date"
                    id="joiningDate"
                    name="joiningDate"
                    className="cursor-pointer"
                  />
                  {errors.joiningDate && touched.joiningDate ? (
                    <div className="text-red-500">{errors.joiningDate}</div>
                  ) : null}

                  <label htmlFor="noticePeriodStartDate">
                    Notice Period Starting Date:
                  </label>
                  <Field
                    type="date"
                    id="noticePeriodStartDate"
                    name="noticePeriodStartDate"
                    className="cursor-pointer"
                  />
                  {errors.noticePeriodStartDate &&
                  touched.noticePeriodStartDate ? (
                    <div className="text-red-500">
                      {errors.noticePeriodStartDate}
                    </div>
                  ) : null}

                  <label htmlFor="noticePeriodEndDate">
                    Notice Period End Date:
                  </label>
                  <Field
                    type="date"
                    id="noticePeriodEndDate"
                    name="noticePeriodEndDate"
                    className="cursor-pointer"
                  />
                  {errors.noticePeriodEndDate && touched.noticePeriodEndDate ? (
                    <div className="text-red-500">
                      {errors.noticePeriodEndDate}
                    </div>
                  ) : null}

                  <Button type="submit" colorScheme="blue" mt={4}>
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onPlacementClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for Record Placement Update */}
      <Modal isOpen={isUpdateOpen} onClose={onUpdateClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Record Placement Update</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                remarks: "",
                isJoined: "",
              }}
              validationSchema={updateValidationSchema}
              onSubmit={(values) => {
                console.log("Update Values", values);
                handleRecordPlacementUpdate(values, applicationId);
                onUpdateClose();
              }}
            >
              {({ errors, touched }) => (
                <Form className="flex flex-col gap-3">
                  <label htmlFor="remarks" className="font-serif font-medium">
                    Remarks:
                  </label>
                  <Field
                    as="textarea"
                    className="bg-gray-200 font-serif font-medium rounded-md outline-none p-4"
                    name="remarks"
                    id="remarks"
                  />
                  {errors.remarks && touched.remarks ? (
                    <div className="text-red-500">{errors.remarks}</div>
                  ) : null}

                  <label className="font-serif font-medium" htmlFor="join">
                    Join?:
                  </label>
                  <Field as="select" name="isJoined" id="join">
                    <option value="">Select</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Field>
                  {errors.isJoined && touched.isJoined ? (
                    <div className="text-red-500">{errors.isJoined}</div>
                  ) : null}

                  <Button type="submit" colorScheme="blue" mt={4}>
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onUpdateClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PopoverPlacement;
