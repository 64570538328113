import React, { Children, useContext } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Button,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  ChakraProvider,
  Box,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { usePipeLine } from "../../../context/PipelineContext";
import { useInterview } from "../../../context/InterviewContext";
const RecordIntResPop = ({
  children,
  applicationID,
  roundno,
  moveToNextRound,
}) => {
  const { recordInterview } = useInterview();

  return (
    <Popover>
      <PopoverTrigger>
        <span>{children}</span>
      </PopoverTrigger>
      <PopoverContent color="black">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Submit Remark</PopoverHeader>
        <PopoverBody>
          <ChakraProvider>
            <Box maxW="md" mx="auto" mt="10">
              <Formik
                initialValues={{ result: "", remark: "" }}
                onSubmit={(values) => {
                  const postData = {
                    roundNo: roundno,
                    result: values.result,
                    remarks: values.remark,
                  };
                  console.log(
                    "postData",
                    postData,
                    "applicationID",
                    applicationID
                  );
                  // recordCvRes(applicationID, postData)
                  recordInterview(applicationID, postData);

                  moveToNextRound();
                }}
              >
                {({ handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <Field name="result">
                      {({ field }) => (
                        <FormControl id="result" mb="4">
                          <FormLabel>Result</FormLabel>
                          <Select {...field} placeholder="Select option">
                            <option value="Passed">Pass</option>
                            <option value="Failed">Fail</option>
                          </Select>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="remark">
                      {({ field }) => (
                        <FormControl id="remark" mb="4">
                          <FormLabel>Remark</FormLabel>
                          <Textarea
                            {...field}
                            placeholder="Enter your remark"
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Button type="submit" colorScheme="blue">
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </Box>
          </ChakraProvider>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default RecordIntResPop;
