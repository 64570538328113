import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  TableContainer,
} from "@chakra-ui/react";
import { RecruimentHeader } from "../component/RecruimentHeader";
import { PipelineContext } from "../../context/PipelineContext";
import { FaWhatsapp } from "react-icons/fa";
import { CgDetailsMore } from "react-icons/cg";
import { useAuth } from "../../context/AuthContext";
import { useJd } from "../../context/JobDescriptContext";
import { SheduleInterviewModal } from "../component/modal/SheduleInterviewModal";
import AdminSubheader from "../../admin/components/AdminSubheader";
import Header from "../../admin/components/Header";
import styles from "../../Styles";
import { RecordOfferContext } from "../../context/RecordOfferContext";
// import RecordCvPop from "../component/popover/RecordCvPop";
// import RecordOfferPop from "../component/popover/RecordOfferPop";
import RecordOfferModal from "../component/modal/RecordOfferModal";
import { formatDate } from "../../Utils/ExtraFun";

export const Interviews = () => {
  const moreDetailsRef = useRef([]);
  const firstFieldRef = useRef(null);
  const { getAllJd, allJds } = useJd();
  const { handleApplicationForm, fetchedData } = useContext(PipelineContext);
  const { userData } = useAuth();
  const [filteredData, setFilteredData] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const [isRecordOfferModalOpen, setIsRecordOfferModalOpen] = useState(false);
  const [applicationId, setApplicationId] = useState(null);

  //for record offer modal
  const openRecordOfferModal = () => setIsRecordOfferModalOpen(true);
  const closeRecordOfferModal = () => setIsRecordOfferModalOpen(false);

  useEffect(() => {
    if (userData?.token) {
      getAllJd();
    }
  }, [userData?.token]);
  useEffect(() => {
    console.log("application Id", applicationId);
  }, [applicationId]);

  useEffect(() => {
    if (!allJds) return;
    console.log("all job description:", allJds);
  }, [allJds]);

  useEffect(() => {
    if (!userData) return;
    handleApplicationForm();
  }, [userData]);

  useEffect(() => {
    const currentState = fetchedData.filter(
      (item) =>
        item?.cvSelectionDetails?.result === "Selected" &&
        item.currentState !== "Candidate Offered" &&
        item.offerDetails?.offerStatus !== "Accepted" &&
        item.interviewProcess.state !== "On Processing"
    );

    console.log("current state:", currentState);
    setFilteredData(currentState);
    setOpenIndex(null);
  }, [fetchedData]);

  return (
    <Fragment>
      {userData.userType === "Recruiter" ? <RecruimentHeader /> : <Header />}
      <AdminSubheader />
      <div className={` bg-black overflow-hidden w-full  h-fit pipeline`}>
        <div className={`${styles.paddingX} ${styles.flexCenter} z-[3]`}>
          <div className={`${styles.boxWidth}`}>
            <h1 className="font-poppins text-blue-500 text-4xl shadow-lg shadow-blue-500 mt-3 text-center">
              Interviews
            </h1>
            <div
              className={`text-white font-poppins text-[16px] border-2 mt-4 max-h-[80vh]  bg-blue-900 shadow-lg shadow-blue-500 items-center overflow-scroll ${styles.boxWidth}`}
              style={{
                scrollbarWidth: "thin",
              }}
            >
              <style>
                {`.text-white::-webkit-scrollbar{
                  width:12px;}
                  .text-white::-webkit-scrollbar-thumb{
                  background-color:blue;
                  border-radius:10px;
                  }
                  .text-white::-webkit-scrollbar-track{
                  background:#ffffff00}`}
              </style>
              <TableContainer
                className="overflow-scroll "
                style={{
                  scrollbarWidth: "thin",
                }}
              >
                <table
                  className="table-auto w-full text-center z-[1]"
                  id="table-to-xls"
                >
                  <thead className="border-b">
                    <tr>
                      <th rowSpan="2" className="border">
                        SL.No
                      </th>
                      <th rowSpan="2" className="border">
                        Application ID
                      </th>
                      <th colSpan="1" className="border">
                        Job Description
                      </th>
                      <th rowSpan="2" className="border">
                        Application Status
                      </th>
                      <th colSpan="1" className="border">
                        Recruiter
                      </th>
                      <th colSpan="4" className="border">
                        Candidate
                      </th>
                      <th rowSpan="2" className="border">
                        Current State
                      </th>
                      <th rowSpan="2" className="border">
                        Remark
                      </th>
                      <th rowSpan="2" className="border">
                        Created Date
                      </th>
                      {userData.userType === "Recruiter" && (
                        <th rowSpan="2" className="border">
                          More
                        </th>
                      )}
                    </tr>
                    <tr>
                      <th className="border">Job ID</th>
                      <th className="border">Emp ID</th>
                      <th className="border">Student ID</th>
                      <th className="border">Email</th>
                      <th className="border">Name</th>
                      <th className="border">Phone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((item, index) => {
                      const allInterviewsPassed = item?.interviewDetails?.every(
                        (detail) => detail.result === "Passed"
                      );
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.applicationID}</td>
                          <td>{item.jobDescription.jobId}</td>
                          <td>{item.applicationStatus}</td>
                          <td>{item.recruiter.empId}</td>
                          <td>{item.candidate.stuId}</td>
                          <td>{item.candidate.email}</td>
                          <td>{item.candidate.name}</td>

                          <td className="flex justify-center  h-20 max-h-screen items-center gap-3">
                            <a
                              href={`https://wa.me/${item.candidate.phone}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="flex items-center border-none"
                            >
                              <FaWhatsapp className="bg-green-500 text-lg rounded-lg border-none" />
                            </a>
                            {item.candidate.phone}
                          </td>
                          <td>{item.currentState}</td>

                          <td>
                            {item.interviewDetails.length > 0 &&
                            item.interviewDetails.some(
                              (detail) => detail.remarks
                            )
                              ? item.interviewDetails.find(
                                  (detail) => detail.remarks
                                )?.remarks
                              : item.cvSelectionDetails.remark}
                          </td>
                          <td>{formatDate(item?.createdAt)}</td>
                          <td>
                            <Menu backgroundColor={"black"}>
                              <MenuButton alignItems={"center"} as={Button}>
                                <CgDetailsMore />
                              </MenuButton>

                              <MenuList bgColor={"black"}>
                                {allJds.length > 0 &&
                                item.currentState === "CV Selected" &&
                                item.interviewDetails.length < 3 ? (
                                  <SheduleInterviewModal
                                    appDetails={item}
                                    applicationID={item._id}
                                    JDID={item?.jobDescription?._id}
                                    allJDs={allJds}
                                  >
                                    <MenuItem
                                      bgColor={"black"}
                                      _hover={{ backgroundColor: "blue.500" }}
                                    >
                                      Schedule Interviews
                                    </MenuItem>
                                  </SheduleInterviewModal>
                                ) : allInterviewsPassed ? (
                                  <MenuItem
                                    bgColor={"black"}
                                    _hover={{ backgroundColor: "blue.500" }}
                                    onClick={() => {
                                      openRecordOfferModal();
                                      setApplicationId(item._id);
                                    }}
                                  >
                                    Record Offer
                                  </MenuItem>
                                ) : (
                                  <SheduleInterviewModal
                                    appDetails={item}
                                    applicationID={item._id}
                                    JDID={item?.jobDescription?._id}
                                    allJDs={allJds}
                                  >
                                    <MenuItem
                                      bgColor={"black"}
                                      _hover={{ backgroundColor: "blue.500" }}
                                    >
                                      Schedule Interviews
                                    </MenuItem>
                                  </SheduleInterviewModal>
                                )}

                                <RecordOfferModal
                                  isOpen={isRecordOfferModalOpen}
                                  onClose={closeRecordOfferModal}
                                  onOpen={openRecordOfferModal}
                                  item={item}
                                  applicationId={applicationId}
                                />
                              </MenuList>
                            </Menu>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </TableContainer>
            </div>
          </div>
        </div>
        <div className="relative z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
        <div className="relative z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-10" />
        <div className="relative z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
      </div>
    </Fragment>
  );
};
