import React, { useContext, useState } from "react";
import { createContext } from "react";
import { useEnv } from "./EnvContext";
import { useAuth } from "./AuthContext";
import axios from "axios";
import toast from "react-hot-toast";
import { sortTransactionsByDate } from "../Utils/ExtraFun";

//create the context
export const JobDescriptionContext = createContext();

//create provider component
export const JobDescriptionContextProvider = ({ children }) => {
  const { backendUrl } = useEnv();
  const { userData } = useAuth();

  const [allJds, setAlljds] = useState([]);
  const [jdData, setJdData] = useState({});

  const handleFormSubmit = async (jdValues) => {
    const token = userData.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading("Submit in progress...", {
        position: "top-right",
        style: {
          marginTop: "62px",
        },
      });
      await axios
        .post(`${backendUrl}/jd`, jdValues, { headers })
        .then((res) => {
          console.log("handle sign res", res.data.data);
          const data = res.data;
          console.log(data);
          localStorage.setItem("JD data", JSON.stringify(res.data.data));
          toast.success(res.data.message, {
            position: "top-right",
            style: {
              marginTop: "62px",
            },
          });
        });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      if (error.response.data.message) {
        toast.error(`${error.response.data.message}`, {
          position: "top-right",
          style: {
            marginTop: "62px",
          },
        });
      } else {
        toast.error("Something went wrong", {
          position: "top-right",
          style: {
            marginTop: "62px",
          },
        });
      }
    } finally {
      //close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const updateJD = async (jdValues, id) => {
    const token = userData.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading("Update in progress...", {
        position: "top-right",
        style: {
          marginTop: "62px",
        },
      });
      await axios
        .put(`${backendUrl}/jd?jdId=${id}`, jdValues, { headers })
        .then((res) => {
          console.log("handle sign res", res.data.data);
          getAllJd();
          toast.success(res.data.message, {
            position: "top-right",
            style: {
              marginTop: "62px",
            },
          });
        });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      if (error.response.data.message) {
        toast.error(`${error.response.data.message}`, {
          position: "top-right",
          style: {
            marginTop: "62px",
          },
        });
      } else {
        toast.error("Something went wrong", {
          position: "top-right",
          style: {
            marginTop: "62px",
          },
        });
      }
    } finally {
      //close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const getAllJd = async () => {
    const token = userData.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await axios
        .get(`${backendUrl}/jd/all`, { headers: headers })
        .then((res) => {
          console.log("getAllJdres", res.data);
          const sortedData = sortTransactionsByDate(res.data);
          console.log(sortedData);
          setAlljds(sortedData);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getJd = async (id) => {
    console.log("getJdid", id);
    const token = userData.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .get(`${backendUrl}/jd?jdId=${id}`, { headers: headers })
        .then((res) => {
          console.log("getJd", res.data);
          setJdData(res.data);
        });
    } catch (err) {
      console.log(err);
    }
  };

  //function for post jd id and candidate id

  const handleApplicationForm = async (jdId, candidateIds) => {
    const token = userData.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading("Submitting candidate ID(s) and JD ID...", {
        position: "top-right",
        style: {
          marginTop: "65px",
        },
      });

      // Ensure candidateIds is always an array
      const candidateIdArray = Array.isArray(candidateIds)
        ? candidateIds
        : [candidateIds];

      for (const candidateId of candidateIdArray) {
        try {
          const res = await axios.post(
            `${backendUrl}/application/start`,
            { candidateId, jobDescriptionId: jdId },
            { headers }
          );
          const data = res.data;
          console.log(data);
          toast.success(`Candidate ${candidateId} submitted successfully`, {
            position: "top-right",
            style: {
              marginTop: "68px",
            },
          });
        } catch (error) {
          console.log("Error sending data for candidateId:", candidateId);
          toast.error(`Error submitting candidate ${candidateId}`, {
            position: "top-right",
            style: {
              marginTop: "68px",
            },
          });
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response?.data?.message) {
        toast.error(`${err.response.data.message}`, {
          position: "top-right",
          style: {
            marginTop: "65px",
          },
        });
      } else {
        toast.error("Something went wrong", {
          position: "top-right",
          style: {
            marginTop: "65px",
          },
        });
      }
    } finally {
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  console.log("all jds:", allJds);

  return (
    <JobDescriptionContext.Provider
      value={{
        handleFormSubmit,
        getAllJd,
        allJds,
        getJd,
        jdData,
        handleApplicationForm,
        updateJD,
      }}
    >
      {children}
    </JobDescriptionContext.Provider>
  );
};

export const useJd = () => useContext(JobDescriptionContext);

// jobDescriptionData, updateJobDescription, handleFormSubmit;
