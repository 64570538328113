import React, { createContext } from "react";
import { useEnv } from "./EnvContext";
import { useAuth } from "./AuthContext";
import toast from "react-hot-toast";
import axios from "axios";
import { usePipeLine } from "./PipelineContext";

export const RecordOfferContext = createContext();
const RecordOfferContextProvider = ({ children }) => {
  const { backendUrl } = useEnv();
  const { userData } = useAuth();
  const { handleApplicationForm } = usePipeLine();

  const handleRecordOffer = async (values, applicationId) => {
    const token = userData?.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let loadingtoast;

    try {
      loadingtoast = toast.loading("submit record offer data....", {
        position: "top-right",
        style: {
          marginTop: "70px",
        },
      });

      let response = await axios.put(
        `${backendUrl}/application/record_offer?applicationId=${applicationId}`,
        values,
        { headers }
      );
      console.log("response:", response);
      const data = response.data;
      console.log("data:", data);
      handleApplicationForm();
      toast.success(data.message, {
        position: "top-right",
        style: {
          marginTop: "65px",
        },
      });
    } catch (error) {
      if (error.response.data.message) {
        toast.error(`${error.response.data.message}`, {
          position: "top-right",
          style: {
            marginTop: "70px",
          },
        });
      } else {
        toast.error("Something Went Wrong", {
          position: "top-left",
          style: {
            marginTop: "65px",
          },
        });
      }
    } finally {
      if (loadingtoast) {
        toast.dismiss(loadingtoast);
      }
    }
  };

  const handleRecordResOffer = async (values, applicationId) => {
    console.log("values:", values);
    const token = userData?.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let loadingtoast;
    try {
      loadingtoast = toast.loading("Submit record offer response...", {
        position: "top-right",
        style: {
          marginTop: "65px",
        },
      });

      let response = await axios.put(
        `${backendUrl}/application/record_res_offer?applicationId=${applicationId}`,
        values,
        { headers }
      );
      const data = response.data;
      console.log(data);
      handleApplicationForm();

      toast.success(data.message, {
        position: "top-right",
        style: {
          marginTop: "70px",
        },
      });
    } catch (error) {
      console.log("Error:", error);
      if (error.response.data.message) {
        toast.error(`${error.response.data.message}`, {
          position: "top-right",
          style: {
            marginTop: "80px",
          },
        });
      } else {
        toast.error("Something Went Wrong....", {
          position: "top-right",
          style: {
            marginTop: "62px",
          },
        });
      }
    } finally {
      if (loadingtoast) {
        toast.dismiss(loadingtoast);
      }
    }
  };
  return (
    <RecordOfferContext.Provider
      value={{ handleRecordOffer, handleRecordResOffer }}
    >
      {children}
    </RecordOfferContext.Provider>
  );
};

export default RecordOfferContextProvider;
