import React, { createContext, useCallback, useMemo } from "react";
import { useEnv } from "./EnvContext";
import { useAuth } from "./AuthContext";
import toast from "react-hot-toast";
import axios from "axios";

export const RejectApplicationContext = createContext();
const RejectApplicationContextProvider = ({ children }) => {
  const { backendUrl } = useEnv();
  const { userData } = useAuth();
  const handleRejectApplication = useCallback(
    async (values, applicationId) => {
      
      const token = userData?.token;
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      let loadingtoast;
      try {
        loadingtoast = toast.loading("Reject application...", {
          position: "top-left",
          style: {
            marginTop: "65px",
          },
        });

        const response = await axios.put(
          `${backendUrl}/application/reject_application?applicationId=${applicationId}`,
          values,
          { headers }
        );
        const data = response.data;
        toast.success(data.message, {
          position: "top-left",
          style: {
            marginTop: "70px",
          },
        });
      } catch (error) {
        console.log("reject error:", error);
        console.log("reject resone:", error.response);
        if (error.response.data.message) {
          toast.error(`${error.response.data.message}`, {
            position: "top-right",
            style: {
              marginTop: "65px",
            },
          });
        } else {
          console.log("Something went wrrong");
          toast.error("Something Went wrong", {
            position: "top-right",
            style: {
              marginTop: "80px",
            },
          });
        }
      } finally {
        if (loadingtoast) {
          toast.dismiss(loadingtoast);
        }
      }
    },
    [backendUrl, userData?.token]
  );

  const contextValue = useMemo(
    () => ({
      handleRejectApplication,
    }),
    [handleRejectApplication]
  );
  return (
    <RejectApplicationContext.Provider value={contextValue}>
      {children}
    </RejectApplicationContext.Provider>
  );
};

export default RejectApplicationContextProvider;
