import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Link } from "react-router-dom";
import _ from "lodash";
import styles from "../../Styles";
import { RecruimentHeader } from "../component/RecruimentHeader";
import { RecruitmentSubHeader } from "../component/RecruitmentSubHeader";
import {
  VStack,
  Text,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  TableContainer,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/react";
import { PiDotsThreeOutlineBold } from "react-icons/pi";
import JdDetails from "../component/modal/JdDetails";
import { useAuth } from "../../context/AuthContext";
import { useJd } from "../../context/JobDescriptContext";
import { formatDate } from "../../Utils/ExtraFun";
import AdminSubheader from "../../admin/components/AdminSubheader";
import Header from "../../admin/components/Header";
import { UpdateJDModal } from "../../admin/components/modal/UpdateJDModal";
import { DeleteJDContext } from "../../context/DeleteJDContext";

export const Requirement = () => {
  const { handleDeleteJd } = useContext(DeleteJDContext);
  const { userData } = useAuth();
  const { getAllJd, allJds, getJd } = useJd();

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!searchTerm) {
        setFilteredData(allJds);
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [searchTerm, allJds]);

  useEffect(() => {
    allJds.map((item, index) => {
      console.log("Item data:", item);
    });
  }, []);

  const handleSearch = (query) => {
    if (query.trim() === "") {
      setFilteredData(allJds);
    } else {
      const filtered = allJds?.filter((job) => {
        const searchTermLower = searchTerm.toLowerCase();

        return (
          job.jobId.toLowerCase().includes(searchTermLower) ||
          job.jobTitle.toLowerCase().includes(searchTermLower) ||
          job.numberOfOpenings.toString().includes(searchTermLower) ||
          job.description.toLowerCase().includes(searchTermLower) ||
          job.relevantExperience.toLowerCase().includes(searchTermLower) ||
          job.experience.toLowerCase().includes(searchTermLower) ||
          job.noOfClosings.toString().includes(searchTermLower) ||
          job.percentage.toString().includes(searchTermLower) ||
          job.salaryOffering.toLowerCase().includes(searchTermLower) ||
          job.similarTags.some((tag) =>
            tag.toLowerCase().includes(searchTermLower)
          ) ||
          job.skills.some((skill) =>
            skill.toLowerCase().includes(searchTermLower)
          ) ||
          job.interviewRounds.some(
            (round) =>
              round.title.toLowerCase().includes(searchTermLower) ||
              round.instructions.toLowerCase().includes(searchTermLower)
          ) ||
          job.companyDetails.companyName
            .toLowerCase()
            .includes(searchTermLower) ||
          job.companyDetails.companyField
            .toLowerCase()
            .includes(searchTermLower) ||
          job?.workingRecruiters?.some((recruiter) =>
            recruiter?.name?.toLowerCase()?.includes(searchTermLower)
          ) ||
          job?.broughtBy?.name?.toLowerCase().includes(searchTermLower)
        );
      });
      setFilteredData(filtered);
    }
  };

  const debouncedHandleSearch = useCallback(
    _.debounce((query) => handleSearch(query), 300),
    [allJds]
  );

  const onSearchChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    debouncedHandleSearch(query);
  };

  useEffect(() => {
    if (userData?.token) {
      getAllJd();
    } else return;
  }, [userData]);

  useEffect(() => {
    if (allJds) {
      console.log("allJds=================", allJds);
      setFilteredData(allJds);
    }
  }, [allJds]);

  return (
    <Fragment>
      {userData.userType === "Recruiter" ? <RecruimentHeader /> : <Header />}
      <AdminSubheader />
      <div className={` bg-black overflow-hidden w-full h-fit pipeline `}>
        <div className={`${styles.paddingX} ${styles.flexCenter} z-[3]`}>
          <div
            className={`${styles.boxWidth} items-center justify-items-center`}
          >
            <h1 className="font-poppins text-blue-500 text-4xl shadow-lg shadow-blue-500 mt-3 text-center">
              Requirements
            </h1>
            <div class="flex justify-end border-2 bg-blue-900 shadow-lg shadow-blue-500 mt-5  rounded-2xl">
              <div className=" justify-items-end flex1">
                <div className="input-group relative flex flex-row justify-end items-stretch w-full mb-4 mr-4 ">
                  <input
                    type="search"
                    className="form-control relative flex-auto min-w-0 block w-full h-[44px] px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none mt-3"
                    placeholder="Search Filters"
                    aria-label="Search"
                    aria-describedby="button-addon3"
                    value={searchTerm}
                    onChange={onSearchChange}
                  />

                  <button
                    class=" mr-5 ml-2 mt-2 btn inline-block px-6 py-2 border-2 border-white text-white font-poppins text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                    type="button"
                    id="button-addon3"
                    onClick={() => handleSearch(searchTerm)}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>

            <div
              className={`text-white font-poppins text-[16px] border-2 mt-5 h-fit mb-10 bg-blue-900 shadow-lg shadow-blue-500 items-center w-full  max-h-[80vh] overflow-y-auto`}
              style={{
                scrollbarWidth: "thin",
              }}
            >
              {/* styling for firefox browser */}
              <style>
                {`.text-white::-webkit-scrollbar{
                  width:12px;}
                  .text-white::-webkit-scrollbar-thumb{
                  background-color:blue;
                  border-radius:10px;
                  }
                  .text-white::-webkit-scrollbar-track{
                  background:#ffffff00}`}
              </style>
              <p className="font-poppins text-[25px] text-center underline">
                Active Requirements
              </p>
              <div>
                <TableContainer
                  style={{
                    scrollbarWidth: "thin",
                  }}
                >
                  <Table id="table-to-xls">
                    <Thead className="border-b sticky top-0 bg-blue-900 border-white z-10">
                      <Tr>
                        <Th
                          w={1}
                          color={"white"}
                          border={"2px"}
                          borderColor={"white"}
                        >
                          Sl No.
                        </Th>
                        <Th
                          color={"white"}
                          border={"2px"}
                          borderColor={"white"}
                        >
                          job Id
                        </Th>
                        <Th
                          color={"white"}
                          border={"2px"}
                          borderColor={"white"}
                        >
                          Job Title
                        </Th>
                        <Th
                          maxW={"20rem"}
                          color={"white"}
                          border={"2px"}
                          borderColor={"white"}
                        >
                          Company Details
                        </Th>
                        <Th
                          color={"white"}
                          border={"2px"}
                          borderColor={"white"}
                        >
                          Skills
                        </Th>
                        <Th
                          color={"white"}
                          border={"2px"}
                          borderColor={"white"}
                        >
                          NO
                        </Th>
                        <Th
                          color={"white"}
                          border={"2px"}
                          borderColor={"white"}
                        >
                          NOC
                        </Th>
                        <Th
                          color={"white"}
                          border={"2px"}
                          borderColor={"white"}
                        >
                          Cloasing Date
                        </Th>
                        <Th
                          color={"white"}
                          border={"2px"}
                          borderColor={"white"}
                        >
                          Ceated Date
                        </Th>
                        <Th
                          color={"white"}
                          border={"2px"}
                          borderColor={"white"}
                        >
                          More
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredData.map((jd, i) => (
                        <Tr key={i}>
                          <Td w={1} py={0} px={2}>
                            {i + 1}
                          </Td>
                          <Td
                            cursor={"pointer"}
                            onClick={() => {
                              getJd(jd?._id);
                            }}
                          >
                            <JdDetails data={allJds[i]}>
                              <Text as={"u"}>{jd?.jobId}</Text>
                            </JdDetails>
                          </Td>
                          <Td>{jd?.jobTitle}</Td>
                          <Td maxW={"20rem"}>
                            <VStack>
                              <Text> {jd?.companyDetails?.companyName}</Text>
                              {/* <Text> {jd?.companyDetails?.companyField}</Text>  */}
                            </VStack>
                          </Td>
                          <Td maxW={"12rem"} py={0} px={2}>
                            <Box
                              overflowY={"scroll"}
                              maxH={"5rem"}
                              overflowX={"hidden"}
                            >
                              {jd?.skills.map((skill, index) => (
                                <Box key={index}>{skill}</Box>
                              ))}
                            </Box>
                          </Td>

                          <Td>{jd?.numberOfOpenings}</Td>
                          <Td>{jd?.noOfClosings}</Td>
                          <Td>{formatDate(jd?.closingDate)}</Td>
                          <Td>{formatDate(jd?.createdAt)}</Td>
                          <Td>
                            <Menu>
                              <MenuButton
                                alignItems={"center"}
                                as={Button}
                                rightIcon={<PiDotsThreeOutlineBold />}
                              ></MenuButton>
                              <MenuList backgroundColor={"black"}>
                                {userData.userType === "Recruiter" && (
                                  <MenuItem
                                    backgroundColor={"black"}
                                    textColor={"white"}
                                    _hover={{ backgroundColor: "blue.500" }}
                                  >
                                    Add Candidate
                                  </MenuItem>
                                )}
                                {(userData.userType === "Admin" ||
                                  userData.userType ===
                                    "Business Developer") && (
                                  <UpdateJDModal jdDetails={jd}>
                                    <MenuItem
                                      backgroundColor={"black"}
                                      textColor={"white"}
                                      _hover={{ backgroundColor: "blue.500" }}
                                    >
                                      Update JD
                                    </MenuItem>
                                  </UpdateJDModal>
                                )}

                                <JdDetails data={allJds[i]}>
                                  <MenuItem
                                    backgroundColor={"black"}
                                    textColor={"white"}
                                    _hover={{ backgroundColor: "blue.500" }}
                                    onClick={() => {
                                      getJd(jd?._id);
                                    }}
                                  >
                                    view Details
                                  </MenuItem>
                                </JdDetails>
                                {userData?.userType === "Admin" ||
                                  (userData.userType ===
                                    "Business Developer" && (
                                    <MenuItem
                                      backgroundColor={"black"}
                                      textColor={"white"}
                                      _hover={{ backgroundColor: "blue.500" }}
                                      onClick={() => handleDeleteJd(jd._id)}
                                    >
                                      Delete JD
                                    </MenuItem>
                                  ))}
                              </MenuList>
                            </Menu>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
        <div className="relative z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
        <div className="relative z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-10" />
        <div className="relative z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
      </div>
    </Fragment>
  );
};
