import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useContext } from "react";
import { RecordOfferContext } from "../../../context/RecordOfferContext";

const RecordOfferModal = ({ item, isOpen, onClose, applicationId }) => {
  const { handleRecordOffer } = useContext(RecordOfferContext);
  const validationSchema = Yup.object().shape({
    newOfferedSalary: Yup.number().required("New Offered salary is Required"),
    fixedComponent: Yup.number().required("Fixed component is Required"),
    variableComponent: Yup.number().required("Variable Component is Required"),
  });
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Record Offer</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              newOfferedSalary: "",
              fixedComponent: "",
              variableComponent: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              console.log("record offer values:", values);
              console.log("Application Id:", applicationId);
              handleRecordOffer(values, applicationId);
              onClose();
            }}
          >
            {({ errors, touched }) => (
              <Form className="flex flex-col gap-3">
                <label htmlFor="new" className="font-serif font-medium">
                  New Offered Salary:
                </label>
                <Field
                  as="input"
                  className="font-serif bg-gray-300 font-medium rounded-md  p-4"
                  name="newOfferedSalary"
                  id="new"
                />
                {errors.newOfferedSalary && touched.newOfferedSalary ? (
                  <div className="text-red-500">{errors.newOfferedSalary}</div>
                ) : null}
                <label htmlFor="fiexd" className="font-serif font-medium">
                  Fixed Component
                </label>
                <Field
                  as="input"
                  className="font-serif bg-gray-300 font-medium rounded-md  p-4"
                  name="fixedComponent"
                  id="fixed"
                />
                {errors.fixedComponent && touched.fixedComponent ? (
                  <div className="text-red-500">{errors.fixedComponent}</div>
                ) : null}
                <label htmlFor="variable" className="font-serif font-medium">
                  Variable Component
                </label>
                <Field
                  as="input"
                  className="font-serif bg-gray-300 font-medium rounded-md  p-4"
                  name="variableComponent"
                  id="variable"
                />
                {errors.variableComponent && touched.variableComponent ? (
                  <div className="text-red-500">{errors.variableComponent}</div>
                ) : null}
                <Button type="submit" colorScheme="blue" mt={4}>
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RecordOfferModal;
