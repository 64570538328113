import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import { EnvContextProvider } from "./context/EnvContext";
import { AuthContextProvider } from "./context/AuthContext";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import { UserContextProvider } from "./context/UserContext";
import { JobDescriptionContextProvider } from "./context/JobDescriptContext";
import { CandidateContextProvider } from "./context/CandidateContext";
import EmployeeContextProvider from "./context/EmployeeContext";
import DeletedEmployeeProvider from "./context/DeletedEmployee";
import PipelineContextProvider from "./context/PipelineContext";
import ApplicationContextProvider from "./context/ApplicationContext";
import InterviewContextProvider from "./context/InterviewContext";
import RecordOfferContextProvider from "./context/RecordOfferContext";
import PlacementContexProvider from "./context/PlacementContex";
import BillingContextProvider from "./context/BillingContext";
import RejectApplicationContextProvider from "./context/RejectApplicationContext";
import RevertApplicationContextProvider from "./context/RevertApplicationContext";
import DeleteCandidateContextProvider from "./context/DeleteCandidateContext";
import DeleteJDContextProvider from "./context/DeleteJDContext";

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({ config });
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ChakraProvider theme={theme}>
      <EnvContextProvider>
        <AuthContextProvider>
          <UserContextProvider>
            <JobDescriptionContextProvider>
              <EmployeeContextProvider>
                <DeletedEmployeeProvider>
                  <CandidateContextProvider>
                    <PipelineContextProvider>
                      <ApplicationContextProvider>
                        <InterviewContextProvider>
                          <RecordOfferContextProvider>
                            <PlacementContexProvider>
                              <BillingContextProvider>
                                <RejectApplicationContextProvider>
                                  <RevertApplicationContextProvider>
                                    <DeleteCandidateContextProvider>
                                      <DeleteJDContextProvider>
                                        <App />
                                      </DeleteJDContextProvider>
                                    </DeleteCandidateContextProvider>
                                  </RevertApplicationContextProvider>
                                </RejectApplicationContextProvider>
                              </BillingContextProvider>
                            </PlacementContexProvider>
                          </RecordOfferContextProvider>
                        </InterviewContextProvider>
                      </ApplicationContextProvider>
                    </PipelineContextProvider>
                  </CandidateContextProvider>
                </DeletedEmployeeProvider>
              </EmployeeContextProvider>
            </JobDescriptionContextProvider>
          </UserContextProvider>
        </AuthContextProvider>
      </EnvContextProvider>
    </ChakraProvider>
  </Router>
);
