import React, { createContext, useCallback, useMemo, useState } from "react";
import { useEnv } from "./EnvContext";
import { useAuth } from "./AuthContext";
import toast from "react-hot-toast";
import axios from "axios";

export const BillingContext = createContext();
const BillingContextProvider = ({ children }) => {
  const { backendUrl } = useEnv();
  const { userData } = useAuth();
  const [fetchBillData, setFetchBillData] = useState();

  //function for hitting billling api, with memoized function
  const handleBilling = useCallback(async () => {
    console.log("fetchBillData", fetchBillData);
    const token = userData?.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let loadingtoast;
    try {
      loadingtoast = toast.loading("Get biiling data....", {
        position: "top-right",
        style: {
          marginTop: "65px",
        },
      });

      const response = await axios.get(`${backendUrl}/billing/all`, {
        headers,
      });

      console.log("response:", response);
      const data = response.data;
      setFetchBillData(data);
      console.log("data:", data);

      toast.success(data.message, {
        position: "top-right",
        style: {
          marginTop: "80px",
        },
      });
    } catch (error) {
      console.log("error:", error);
      console.log("response error:", error.response);
      if (error.response.data.message) {
        toast.error(`${error.response.data.message}`, {
          position: "top-right",
          style: {
            marginTop: "80px",
          },
        });
      } else {
        toast.error("Something went wrong", {
          position: "top-right",
          style: {
            marginTop: "67px",
          },
        });
      }
    } finally {
      if (loadingtoast) {
        toast.dismiss(loadingtoast);
      }
    }
  }, [backendUrl, userData?.token]);

  const contextValue = useMemo(
    () => ({
      handleBilling,
      fetchBillData,
    }),
    [handleBilling, fetchBillData]
  );

  return (
    <BillingContext.Provider value={contextValue}>
      {children}
    </BillingContext.Provider>
  );
};

export default BillingContextProvider;
