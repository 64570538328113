import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import React from "react";
import { IoFilter } from "react-icons/io5";

const CVprocesMenu = ({ fetchedData, setFilteredData, filteredData }) => {
  const handleCVprocess = (filter) => {
    switch (filter) {
      case "Not Started":
        const notStarted = fetchedData.filter(
          (item) => item.cvProcess === "Not Started"
        );
        console.log("notstarted:", notStarted);
        setFilteredData(notStarted);
        break;

      case "Completed":
        const completed = fetchedData.filter(
          (item) => item.cvProcess === "Completed"
        );
        console.log("completed:", completed);
        setFilteredData(completed);
        break;

      case "On Process":
        const onProcess = fetchedData.filter(
          (item) => item.cvProcess === "On Process"
        );
        console.log("On Process:", onProcess);
        setFilteredData(onProcess);
        break;

      default:
        // If the filter doesn't match any case, you can handle it here
        setFilteredData(fetchedData); // For example, show all data if no filter is matched
        break;
    }
  };

  return (
    <Menu>
      <MenuButton as={Button} rightIcon={<IoFilter />}>
        CV Process
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => handleCVprocess("All")}>All</MenuItem>
        <MenuItem onClick={() => handleCVprocess("Not Started")}>
          Not Started
        </MenuItem>
        <MenuItem onClick={() => handleCVprocess("On Process")}>
          On Process
        </MenuItem>
        <MenuItem onClick={() => handleCVprocess("Completed")}>
          Completed
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default CVprocesMenu;
