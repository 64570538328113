import React from "react";
import { useState } from "react";
import RecruiterForm from "./forms/RecruiterForm";

import styles from "../Styles";
import { RecruiterNewHeader } from "../recruiter/component/RecruiterNewHeader";

export const UserIdGenerator = () => {
  return (
    <>
      <div className={` bg-black overflow-hidden w-full  h-[1280px] pipeline `}>
        <div className={`${styles.paddingX} ${styles.flexCenter} z-[3]`}>
          <div
            className={`${styles.boxWidth} items-center justify-items-center`}
          >
            <h1 className="font-poppins text-blue-500 text-4xl shadow-lg shadow-blue-500 mt-3 text-center">
              User ID generator
            </h1>
            <div
              className={`text-white   font-poppins text-[16px] border-2 mt-10 h-[90%] bg-blue-900 shadow-lg shadow-blue-500  items-center overflow-hidden ${styles.boxWidth}`}
            >
              <RecruiterForm />.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
