import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Button,
  ListItem,
  Box,
  HStack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  SimpleGrid,
  Tag,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useJd } from "../../../context/JobDescriptContext";
import { formatDate } from "../../../Utils/ExtraFun";

function JdDetails({ children }) {
  const { jdData } = useJd();
  useEffect(() => {
    if (!jdData) return;

    console.log(jdData);
  }, [jdData]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        size={"xl"}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Job Description Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              <strong>Job Id:</strong> {jdData.jobId}
            </Text>
            <Text>
              {" "}
              <strong>Job Title:</strong> {jdData.jobTitle}
            </Text>
            {jdData?.similarTags?.length > 0 && (
              <>
                <Text>
                  {" "}
                  <strong>Similar Tags:</strong>
                </Text>
                {jdData?.similarTags?.map((similarTags, index) => (
                  <ul key={index}>
                    <li>{similarTags}</li>
                  </ul>
                ))}
              </>
            )}
            <Text as="strong">Skills:</Text>
            <SimpleGrid columns={[2, null, 3]} spacing={4} mt={2}>
              {jdData?.skills?.map((skill, index) => (
                <Tag
                  size="lg"
                  key={index}
                  variant="solid"
                  colorScheme="blue"
                  borderRadius="full"
                >
                  {skill}
                </Tag>
              ))}
            </SimpleGrid>
            <Text>
              {" "}
              <strong>Description :</strong>
              {jdData.description}
            </Text>
            <Text>
              {" "}
              <strong>Experience :</strong>
              {jdData.experience}
            </Text>
            <Text>
              {" "}
              <strong>Relevant Experience:</strong>
              {jdData.relevantExperience}
            </Text>

            <Text>
              <strong>Interview Rounds:</strong>
            </Text>
            <Table variant="simple" colorScheme="whiteAlpha">
              <Thead bg="blue.900">
                <Tr>
                  <Th color="white">Round No</Th>
                  <Th color="white">Title</Th>
                  <Th color="white">Instructions</Th>
                </Tr>
              </Thead>
              <Tbody bg="white" color="black">
                {jdData?.interviewRounds?.map((interviewRound, index) => (
                  <Tr key={index}>
                    <Td>{interviewRound?.roundNo}</Td>
                    <Td>{interviewRound?.title}</Td>
                    <Td>{interviewRound?.instructions}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Text>
              {" "}
              <strong>Closing Date:</strong>
              {formatDate(jdData.closingDate)}
            </Text>

            <Text>
              {" "}
              <strong>Salary Offering:</strong>
              {jdData.salaryOffering}
            </Text>
            <Text>
              {" "}
              <strong>No. Of Openings:</strong>
              {jdData.numberOfOpenings}
            </Text>
            <Text>
              {" "}
              <strong>No. Of Closings:</strong>
              {jdData.noOfClosings}
            </Text>
            <Text>
              {" "}
              <strong>Percentage:</strong>
              {jdData.percentage}
            </Text>
            <Text>
              {" "}
              <strong style={{ textDecoration: "underline" }}>
                BDE :
              </strong>{" "}
              {jdData?.broughtBy?.name}
            </Text>
            <Text as="u">
              <strong>Working Recruiters:</strong>
            </Text>
            <Table variant="simple" colorScheme="whiteAlpha">
              <Thead bg="blue.900">
                <Tr>
                  <Th color="white">#</Th>
                  <Th color="white">Emp Id</Th>
                  <Th color="white">Name</Th>
                </Tr>
              </Thead>
              <Tbody bg="white" color="black">
                {jdData?.workingRecruiters?.map((workingRecruiter, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{workingRecruiter?.empId}</Td>
                    <Td>{workingRecruiter?.name}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* <Text>companyDetails:
                            {jdData?.companyDetails.map((companyDetails, index) => (
                                <ul key={index}>
                                    <li>{companyDetails.companyName}</li>
                                    <li>{companyDetails.companyField}</li>
                                </ul>
                            ))}</Text> */}
    </>
  );
}

export default JdDetails;
