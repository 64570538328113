import { HamburgerIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { RevertApplicationContext } from "../../../context/RevertApplicationContext";
import { PipelineContext } from "../../../context/PipelineContext";

const RevertMenu = ({ item }) => {
  const { handleRevertApplication } = useContext(RevertApplicationContext);

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<HamburgerIcon />}
        variant={"outline"}
        color={"black"}
        bg={"white"}
      />
      <MenuList>
        <MenuItem
          color={"black"}
          onClick={() => {
            console.log("application id", item._id);
            handleRevertApplication(item._id);
          }}
        >
          Revert Application
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default RevertMenu;
