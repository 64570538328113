import React, { useState } from "react";
import styles from "../../Styles";
import { Link, NavLink } from "react-router-dom";
import { logo2, close, menu } from "../../assets";
import { useAuth } from "../../context/AuthContext";

export default function Header() {
  const { handleSignOut, userData } = useAuth();
  const [toggle, setToggle] = useState(false);

  function refreshPage() {
    console.log(window);
    window.location.reload();
  }

  return (
    <div className={` bg-black  w-full h-fit `}>
      <div className={`${styles.paddingX} ${styles.flexCenter} z-[3] `}>
        <div className={`${styles.boxWidth} items-center `}>
          <nav className="w-full flex py-6 justify-between items-center navbar   ">
            <img
              src={logo2}
              alt="Daya-Consultancy"
              className="w-[150px] h-[60px] brand"
            />

            <div className=" flex flex-1 justify-end items-center">
              <img
                src={toggle ? close : menu}
                alt="menu"
                className="w-[28px] h-[28px] object-contain"
                onClick={() => setToggle(!toggle)}
              />

              <div
                className={`${!toggle ? "hidden" : "flex"
                  } p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}
              >
                <ul className="list-none flex justify-end items-start flex-1 flex-col">
                  {
                    userData.userType === 'Admin' &&
                    <li class="nav-item ">
                      <Link
                        className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px]`}
                        aria-current="page"
                        to="/admin/user_id_generator"
                      >
                        User ID Generator
                      </Link>
                    </li>
                  }

                  <li class="nav-item">
                    <Link
                      className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px]`}
                      to="/admin/job_description"
                    >
                      Add Job Description
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px]`}
                      onClick={refreshPage}
                    >
                      Refresh
                    </Link>
                  </li>
                  <li class="nav-item">
                    <p
                      className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px]`}
                      onClick={handleSignOut}
                    >
                      Logout
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
