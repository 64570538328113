export const sortTransactionsByDate = (transactions) => {
  return transactions.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );
};

export function getInterviewRoundsByJDID(allJDs, JDID) {
  const jobDescription = allJDs.find((jd) => jd._id === JDID);
  return jobDescription ? jobDescription.interviewRounds : null;
}

export function formatDate(dateString) {
  if (!dateString) return;
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
}

export const formatTime = (dateString) => {
  if (!dateString) return;
  const date = new Date(dateString);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const filterInterviewDetailsByRoundNo = (interviewDetails, roundNo) => {
  return interviewDetails.filter(detail => detail.roundNo === roundNo);
};

export const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
};
