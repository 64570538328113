import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import {
  filterInterviewDetailsByRoundNo,
  formatDate,
  formatTime,
  getInterviewRoundsByJDID,
} from "../../../Utils/ExtraFun";
import InterviewSchedule from "../popover/InterviewPop";
import RecordIntResPop from "../popover/RecordIntResPop";
import { useAuth } from "../../../context/AuthContext";

export const SheduleInterviewModal = ({
  children,
  allJDs,
  JDID,
  applicationID,
  appDetails,
}) => {
  // const { backendUrl } = useContext(EnvContext);
  const { userData } = useAuth();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [interviewRound, setInterviewRound] = useState([]);
  const [currentRound, setCurrentRound] = useState(1);
  const [interviewProcessState, setInterviewProcessState] =
    useState("On Process");

  useEffect(() => {
    if (!JDID || !allJDs) return;
    const interviewRounds = getInterviewRoundsByJDID(allJDs, JDID);
    console.log("interview Rounds:", interviewRounds);
    if (interviewRounds) {
      setInterviewRound(interviewRounds);
    }
  }, [JDID, allJDs]);

  useEffect(() => {
    console.log("interview round:", interviewRound);
  }, [interviewRound]);

  const handleNextRound = () => {
    if (currentRound < 3) {
      setCurrentRound(currentRound + 1);
    } else {
      setInterviewProcessState("Completed");
    }
  };

  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal
        isCentered
        isOpen={isOpen}
        size={"full"}
        scrollBehavior={"inside"}
        onClose={onClose}
        bg="black"
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.8)" />
        <ModalContent bg="black" color="white">
          <ModalHeader>Rounds</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack w={"100%"}>
              <Table w={"40%"}>
                <Thead bg="teal.500">
                  <Tr>
                    <Th color="white" textAlign="center">
                      Round No
                    </Th>
                    <Th color="white" textAlign="center">
                      Title
                    </Th>
                    <Th color="white" textAlign="center">
                      Location/Mode
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {interviewRound?.length > 0 ? (
                    interviewRound.map((round, index) => (
                      <Tr
                        key={index}
                        sx={{ height: "3.3rem", maxHeight: "3.3rem" }}
                      >
                        <Td textAlign="center" p={2}>
                          {round.roundNo}
                        </Td>
                        <Td textAlign="center" p={2}>
                          {round.title}
                        </Td>
                        <Td textAlign="center" p={2}>
                          {round.locationOrMode}
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={4} textAlign="center" p={2}>
                        No interview rounds found for this job ID.
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>

              <Table>
                <Thead bg="teal.500">
                  <Tr>
                    <Th color="white" textAlign="center">
                      Sch. Date
                    </Th>
                    <Th color="white" textAlign="center">
                      Sch. On
                    </Th>
                    <Th color="white" textAlign="center">
                      Sch. Time
                    </Th>
                    <Th color="white" textAlign="center">
                      Status
                    </Th>
                    <Th color="white" textAlign="center">
                      Result
                    </Th>
                    <Th color="white" textAlign="center">
                      Remark
                    </Th>
                    {userData.userType === "Recruiter" && (
                      <Th color="white" textAlign="center">
                        Handle Ints
                      </Th>
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {interviewRound?.length > 0 ? (
                    interviewRound.map((round, index) => {
                      // Find the interview detail object that matches the round number
                      const interviewDetail = appDetails.interviewDetails.find(
                        (detail) => detail.roundNo === round.roundNo
                      );

                      // Find the interview process object that matches the round number
                      const interviewProcess = appDetails.interviewProcess.find(
                        (process) => process.roundNo === round.roundNo
                      );

                      console.log("interview Process:", interviewProcess);

                      const hasInterviewProcessDetails =
                        index !== 0 &&
                        appDetails.interviewProcess.some(
                          (process) => process.roundNo === index
                        );

                      {
                        /* console.log("index", index, hasInterviewProcessDetails); */
                      }
                      // Render either the matched data or placeholders
                      return (
                        <Tr
                          key={index}
                          sx={{ height: "3.3rem", maxHeight: "3.3rem" }}
                        >
                          <Td textAlign="center" p={2}>
                            {interviewDetail
                              ? formatDate(interviewDetail.schedulingDate)
                              : ""}
                          </Td>
                          <Td textAlign="center" p={2}>
                            {interviewDetail
                              ? formatDate(interviewDetail.scheduledOn)
                              : ""}
                          </Td>
                          <Td textAlign="center" p={2}>
                            {interviewDetail
                              ? formatTime(interviewDetail.scheduledOn)
                              : ""}
                          </Td>
                          <Td textAlign="center" p={2}>
                            {interviewProcess ? interviewProcess.state : ""}
                          </Td>
                          <Td textAlign="center" p={2}>
                            {interviewDetail ? interviewDetail.result : ""}
                          </Td>
                          <Td textAlign="center" p={2}>
                            {interviewDetail ? interviewDetail.remarks : ""}
                          </Td>
                          {userData.userType === "Recruiter" && (
                            <Td textAlign="center" p={1}>
                              {index === 0 || hasInterviewProcessDetails ? (
                                interviewProcess &&
                                interviewProcess?.state === "Completed" ? (
                                  ""
                                ) : interviewProcess &&
                                  interviewProcess?.state === "On Process" ? (
                                  <RecordIntResPop
                                    roundno={round.roundNo}
                                    applicationID={applicationID}
                                  >
                                    <Button
                                      colorScheme="teal"
                                      size="xs"
                                      onClick={() => {
                                        console.log("roundno.", round.roundNo);
                                      }}
                                    >
                                      Record Int. Res.
                                    </Button>
                                  </RecordIntResPop>
                                ) : (
                                  <InterviewSchedule
                                    roundno={round.roundNo}
                                    applicationID={applicationID}
                                  >
                                    <Button
                                      colorScheme="teal"
                                      size="xs"
                                      onClick={() => {
                                        console.log("roundno.", round.roundNo);
                                      }}
                                    >
                                      Schedule
                                    </Button>
                                  </InterviewSchedule>
                                )
                              ) : (
                                " "
                              )}
                            </Td>
                          )}
                        </Tr>
                      );
                    })
                  ) : (
                    <Tr>
                      <Td colSpan={7} textAlign="center" p={2}>
                        No interview rounds found for this job ID.
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
