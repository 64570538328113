import React, {  createContext, useContext, useEffect,useState } from 'react'

const EnvContext =createContext();

export const EnvContextProvider = ({children})=>{
    const [backendUrl, setBackendUrl] = useState(process.env.REACT_APP_BACKEND_URL || '');

    useEffect(()=>{
        setBackendUrl(process.env.REACT_APP_BACKEND_URL)
    },[])

return(
    <EnvContext.Provider value={{backendUrl}}>
{children}
    </EnvContext.Provider>
)

}
export default EnvContext

export const useEnv = () => useContext(EnvContext);