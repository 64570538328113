export const compareDataArrays = (arr1, arr2) => {
  let matchCount = 0;
  let totalCount = 0;

  const deepCompare = (obj1, obj2) => {
    if (
      typeof obj1 === "object" &&
      obj1 !== null &&
      typeof obj2 === "object" &&
      obj2 !== null
    ) {
      const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
      totalCount += keys.size;
      keys.forEach((key) => {
        if (obj1[key] !== undefined && obj2[key] !== undefined) {
          deepCompare(obj1[key], obj2[key]);
        } else {
          totalCount++;
        }
      });
    } else {
      totalCount++;
      if (obj1 === obj2) {
        matchCount++;
      }
    }
  };

  arr1?.forEach((obj1, index) => {
    if (arr1[index]) {
      deepCompare(obj1, arr2[index]);
    } else {
      totalCount += Object.keys(obj1).length;
    }
  });

  arr2?.forEach((obj2, index) => {
    if (!arr1[index]) {
      totalCount += Object.keys(obj2).length;
    }
  });


  return (matchCount / totalCount) * 100;

  
};
