import React, { Fragment, useContext, useEffect, useState } from "react";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";
import styles from "../../Styles";
import { RecruimentHeader } from "../component/RecruimentHeader";
import { RecruitmentSubHeader } from "../component/RecruitmentSubHeader";
import Header from "../../admin/components/Header";
import { useAuth } from "../../context/AuthContext";
import AdminSubheader from "../../admin/components/AdminSubheader";
import { usePipeLine } from "../../context/PipelineContext";
import { BillingContext } from "../../context/BillingContext";
import { formatDate } from "../../Utils/ExtraFun";

export const FeesShare = () => {
  const { userData } = useAuth();
  const { handleApplicationForm, fetchedData } = usePipeLine();
  const { handleBilling, fetchBillData } = useContext(BillingContext);
  const [filterFreeshareData, setFilterFreeshareDat] = useState();

  useEffect(() => {
    if (userData?.token) {
      handleBilling();
    }
  }, [userData?.token]);

  useEffect(() => {
    console.log("fetch data:", fetchBillData);
    console.log("Fetchdata billing:", fetchBillData?.billings);
    setFilterFreeshareDat(fetchBillData?.billings);
  }, [fetchBillData]);

  return (
    <Fragment>
      {userData.userType === "Recruiter" ? <RecruimentHeader /> : <Header />}
      <AdminSubheader />
      <div className={`  bg-black overflow-hidden w-full  h-[1280px] pipeline`}>
        <div className={`${styles.paddingX} ${styles.flexCenter} z-[3]`}>
          <div className={`${styles.boxWidth}`}>
            <h1 className="font-poppins text-blue-500 text-4xl shadow-lg shadow-blue-500 mt-3 text-center">
              Fees Share
            </h1>
            <div
              className={`text-white font-poppins text-[16px]  border-2 mt-4 h-[500px] bg-blue-900  shadow-lg shadow-blue-500  items-center overflow-scroll scroll-smooth ${styles.boxWidth}`}
            >
              <table
                className="table-auto w-full text-center z-[1] "
                id="table-to-xls"
              >
                <thead className="border-b">
                  <tr>
                    <th rowSpan={2} className="border">
                      S.N
                    </th>
                    <th rowSpan={2} className="border">
                      Application Id
                    </th>
                    <th rowSpan={2} className="border">
                      Recruiter Name
                    </th>
                    <th rowSpan={2} className="border">
                      Placemnt Id
                    </th>
                    <th rowSpan={2} className="border">
                      JD Id
                    </th>
                    <th colSpan={4} className="border">
                      Candidate
                    </th>
                    <th rowSpan={2} className="border">
                      CTC
                    </th>
                    <th rowSpan={2} className="border">
                      Percentage Share
                    </th>
                    <th rowSpan={2} className="border">
                      Amount
                    </th>
                    <th rowSpan={2} className="border">
                      Billing Date
                    </th>
                  </tr>
                  <tr>
                    <th className="border">Student Id</th>
                    <th className="border">Email</th>
                    <th className="border">Name</th>
                    <th className="border">Phone</th>
                  </tr>
                </thead>
                <tbody>
                  {filterFreeshareData?.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.application?.applicationID}</td>
                      <td>{item?.recruiter.name}</td>
                      <td>
                        {item?.application?.placementDetails?.placementId}
                      </td>
                      <td>{item?.application?.jobDescription.jobId}</td>
                      <td>{item?.application?.candidate?.stuId}</td>
                      <td>{item?.application?.candidate?.email}</td>
                      <td>{item?.application?.candidate?.name}</td>
                      <td>{item?.application?.candidate?.phone}</td>
                      <td>{item?.ctc}</td>
                      <td>{item?.pshare}</td>
                      <td>{item?.amount}</td>
                      <td>{formatDate(item.billingDate)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="relative z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
        <div className="relative z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-10" />
        <div className="relative z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
      </div>
    </Fragment>
  );
};
