import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { close, logo2, menu, arrowUp, discount } from "../assets";
import styles from "../Styles";
import { stats } from "../constants";
import { useAuth } from "../context/AuthContext";
import toast from "react-hot-toast";
import { Text } from "@chakra-ui/react";

export const HomePage = () => {
  const navigate = useNavigate();
  const { handleSignIn, userData } = useAuth();
  const [toggle, setToggle] = useState(false);
  const initialValues = { email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSignIn(formValues);

    setFormErrors(formValues);
    setIsSubmit(true);
  };
  const storedUserData = localStorage.getItem("userData");
  useEffect(() => {
    if (!userData) {
      return;
    }
    // const data = JSON?.parse(userData)
    const role = userData.userType;
    console.log("role", role);
    switch (role) {
      case "Admin":
        navigate("/admin/candidate");
        break;
      case "HR Manager":
        navigate("/hr/candidate");
        break;
      case "Business Developer":
        navigate("/bde/candidate");
        break;
      case "Recruiter":
        navigate("/candidate");
        break;
      default:
        toast.error("Role not recognized!");
        break;
    }
  }, [userData]);

  useEffect(() => {
    console.log("formErrors", formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log("formValues", formValues);
    }
  }, [formErrors]);
  // const validate = (values) => {
  //   const errors = {};
  //   // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  //   if (!values.email) {
  //     errors.email = "email is required!";
  //   }
  //   // if (!values.email) {
  //   //     errors.email = "Email is required!";
  //   // } else if (!regex.test(values.email)) {
  //   //     errors.email = "This is not a valid email format!";
  //   // }
  //   if (!values.password) {
  //     errors.password = "password is required";
  //   } else if (values.password.length < 4) {
  //     errors.password = "password must be more than 4 characters";
  //   } else if (values.password.length > 10) {
  //     errors.password = "password cannot exceed more than 10 characters";
  //   }
  //   return errors;
  // };

  return (
    <div className="bg-black w-full h-[100vh] overflow-hidden">
      <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <nav className="w-full flex py-6 justify-between items-center navbar">
            <img src={logo2} alt="Daya-Consultancy" className=" h-[77px] " />
            <ul className="list-none sm:flex hidden justify-end items-center flex-1">
              <li className="nav-item text-white">
                <Link
                  className={`nav-link active font-poppins font-normal cursor-pointer text-[16px] mx-[20px] `}
                  aria-current="page"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px] `}
                  to="/candidate"
                >
                  Dashboard
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px] mx-[20px]`}
                  to="/admin/candidate"
                  aria-current="page"
                >
                  Admin
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px] mx-[20px]`}
                  to="#"
                >
                  Clients
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px]`}
                  to="/About"
                >
                  About
                </Link>
              </li>
            </ul>

            <div className="sm:hidden flex flex-1 justify-end items-center">
              <img
                src={toggle ? close : menu}
                alt="menu"
                className="w-[28px] h-[28px] object-contain"
                onClick={() => setToggle(!toggle)}
              />

              <div
                className={`${
                  !toggle ? "hidden" : "flex"
                } p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}
              >
                <ul className="list-none flex justify-end items-start flex-1 flex-col">
                  <li class="nav-item ">
                    <Link
                      className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px]`}
                      aria-current="page"
                      to="/"
                    >
                      Home
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px]`}
                      to="/candidate"
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px]`}
                      to="#"
                    >
                      Clients
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px]`}
                      to="/About"
                    >
                      About
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div className={`bg-black ${styles.flexCenter} w-full`}>
        <div className={`${styles.boxWidth}`}>
          <section
            id="home"
            className={`flex md:flex-row flex-row ${styles.paddingY}`}
          >
            <div
              className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6  text-white`}
            >
              <div className="flex flex-row items-center py-[6px] px-4 bg-discount-gradient rounded-[10px] mb-2">
                <img
                  src={discount}
                  alt="discount"
                  className="w-[32px] h-[32px]"
                />
                <p className={`${styles.paragraph} ml-2`}>
                  <span className="text-white">Daya</span> Consultancy Service{" "}
                  {/* <span className="text-white">1 Month</span> Account */}
                </p>
              </div>

              <div className="flex flex-row justify-between items-center shadow-lg  w-full">
                <h1 className="flex-1 font-poppins font-semibold ss:text-[72px] text-[52px] text-white ss:leading-[100.8px] leading-[75px]">
                  The Daya <br className="sm:block hidden" />{" "}
                  <span className="text-gradient">
                    Generation Resume Finalyzer
                  </span>{" "}
                </h1>
                <div className="ss:flex hidden md:mr-4 mr-0">
                  <div
                    className={`${styles.f} w-[140px] h-[140px] rounded-full bg-blue-gradient p-[2px] cursor-pointer`}
                  >
                    <div
                      className={`${styles.flexCenter} flex-col bg-primary w-[100%] h-[100%] rounded-full`}
                    >
                      <div className={`${styles.flexStart} flex-row`}>
                        <p className="font-poppins font-medium text-[18px] leading-[23.4px]">
                          <span className="text-gradient">Get</span>
                        </p>
                        <img
                          src={arrowUp}
                          alt="arrow-up"
                          className="w-[23px] h-[23px] object-contain"
                        />
                      </div>

                      <p className="font-poppins font-medium text-[18px] leading-[23.4px]">
                        <span className="text-gradient">Started</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/*
        <h1
            className="font-poppins font-semibold ss:text-[68px] text-[52px] text-white ss:leading-[100.8px] leading-[75px] w-full">
            Payment Method.
        </h1> */}
              <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
                Our team of experts uses a methodology to identify the resumes
                most likely to fit your needs. We examine candidate skills and
                placed them into different companies .
              </p>
            </div>

            <div
              className={` w-[40%] shadow-lg flex-wrap shadow-amber-500 flex place-content-center ${styles.flexCenter}
    items-center border-[2px] border-white h-[420px] rounded-[20px] relative `}
            >
              {/* <img src={robot} alt="billing" className="w-[100%] h-[100%] relative z-[5]" /> */}
              <div
                className={` flex mx-[50px] text-white cursor-pointer w-[full] overflow-hidden `}
              >
                <div className={` ${styles.flexCenter} z-[3]  `}>
                  {Object.keys(formErrors).length === 0 && isSubmit ? (
                    <div className={`${styles.boxWidth}`}>
                      Signed in successfully
                    </div>
                  ) : (
                    <pre>{JSON.stringify()}</pre>
                  )}

                  <form onSubmit={handleSubmit}>
                    <h1>
                      <span className="text-gradient  text-[40px]">
                        SignIn Form{" "}
                      </span>{" "}
                    </h1>
                    <div>
                      <label
                        className={` flex font-poppins font-semibold text-[20px]`}
                      >
                        Email
                      </label>
                      <br />
                      <input
                        className={` flex  font-poppins text-center mt-[-15px] text-black focus:outline-none focus:shadow-outline `}
                        type="text"
                        name="email"
                        placeholder="email/Email"
                        value={formValues.email}
                        onChange={handleChange}
                      />
                    </div>
                    <p className="text-red-500">{formErrors.email}</p>
                    <p>{formErrors.email}</p>
                    <div className=" ">
                      <label
                        className={` flex font-poppins font-semibold text-[20px] mt-[4px]`}
                      >
                        password
                      </label>
                      <br />
                      <input
                        className={` flex mt-[-15px] font-poppins text-center text-black focus:outline-none focus:shadow-outline`}
                        type="password"
                        name="password"
                        placeholder="password"
                        value={formValues.password}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </div>
                    <p className="text-red-500">{formErrors.password}</p>
                    <div
                      className={` flex z-[3] text-white text-[20px] m-5 shadow-lg shadow-amber-100 font-poppins font-semibold
            border-[2px] w-[100px] ml-[50px] justify-center `}
                    >
                      <button className="  text-amber-500 hover:text-amber-600 ">
                        {" "}
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className=" flex  mt-[300px] z-[3] justify-center  ml-[-250px] w-[200px] text-center px-[2px]">
                <Link
                  className=" w-[150px] justify-center  underline  text-blue-500"
                  to="/user/forgotpassword"
                >
                  Forgot password?
                </Link>
              </div>

              {/* gradient start */}
              <div className="absolute z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
              <div className="absolute z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-40" />
              <div className="absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
              {/* gradient end */}
            </div>
            {/* 
            <div className={`ss:hidden ${styles.flexCenter}`}>
              <div className={`${styles.f} w-[140px] h-[140px] rounded-full bg-blue-gradient p-[2px] cursor-pointer`}>
                <div className={`${styles.flexCenter} flex-col bg-primary w-[100%] h-[100%] rounded-full`}>
                  <div className={`${styles.flexStart} flex-row`}>
                    <p className="font-poppins font-medium text-[18px] leading-[23.4px]">
                      <span className="text-gradient">Get</span>
                    </p>
                    <img src={arrowUp} alt="arrow-up" className="w-[23px] h-[23px] object-contain" />
                  </div>

                  <p className="font-poppins font-medium text-[18px] leading-[23.4px]">
                    <span className="text-gradient">Started</span>
                  </p>
                </div>
              </div>
            </div> */}
          </section>
        </div>
      </div>

      {/* <div className={`bg-black ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <Stats />
          <Business />
          <Billing />
          <CardDeal />
          <Testimonials />
          <Clients />
          <CTA />

        </div>
      </div> */}
    </div>
  );
};
