import React, { Fragment, useEffect, useState } from "react";
import styles from "../../Styles";
import { RecruimentHeader } from "../component/RecruimentHeader";
import { RecruitmentSubHeader } from "../component/RecruitmentSubHeader";
import Header from "../../admin/components/Header";
import { useAuth } from "../../context/AuthContext";
import AdminSubheader from "../../admin/components/AdminSubheader";
import { usePipeLine } from "../../context/PipelineContext";

import PopoverPlacement from "../component/popover/PopoverPlacement";
import { formatDate } from "../../Utils/ExtraFun";

export const Placements = () => {
  const { userData } = useAuth();
  const { handleApplicationForm, fetchedData } = usePipeLine();
  const [placementData, setPlacementData] = useState([]);

  useEffect(() => {
    if (userData.token) {
      handleApplicationForm();
    }
  }, [userData.token]);

  useEffect(() => {
    if (fetchedData.length > 0) {
      const filterPlacements = fetchedData.filter(
        (item) => item.offerDetails.offerStatus === "Accepted"
      );
      console.log("filter data:", filterPlacements);
      setPlacementData(filterPlacements);
    }
  }, [fetchedData]);

  return (
    <Fragment>
      {userData.userType === "Recruiter" ? <RecruimentHeader /> : <Header />}
      <AdminSubheader />
      <div className={`bg-black overflow-hidden w-full h-[1280px] pipeline`}>
        <div className={`${styles.paddingX} ${styles.flexCenter} z-[3]`}>
          <div className={`${styles.boxWidth}`}>
            <h1 className="font-poppins text-blue-500 text-4xl shadow-lg shadow-blue-500 mt-3 text-center">
              Placements
            </h1>
            <div
              className={`text-white font-poppins text-[16px] relative border-2 mt-4 h-[500px] bg-blue-900 shadow-lg shadow-blue-500 items-center overflow-scroll scroll-smooth ${styles.boxWidth}`}
              style={{ scrollbarWidth: "none" }}
            >
              <table
                className="table-auto w-full text-center z-[1]"
                id="table-to-xls"
              >
                <thead className="border-b sticky top-0">
                  <tr>
                    <th rowSpan={2} className="border">
                      S.N
                    </th>
                    <th rowSpan={2} className="border">
                      Application Id
                    </th>
                    <th rowSpan={2} className="border">
                      Emp Id
                    </th>
                    <th rowSpan={2} className="border">
                      Placements ID
                    </th>
                    <th rowSpan={2} className="border">
                      JD Id
                    </th>
                    <th colSpan={4} className="border">
                      Candidate
                    </th>
                    <th rowSpan={2} className="border">
                      Join Date
                    </th>
                    <th rowSpan={2} className="border">
                      Notice Period Starting
                    </th>
                    <th rowSpan={2} className="border">
                      Notice Period Ending
                    </th>
                    <th rowSpan={2} className="border">
                      Placement Date
                    </th>
                    <th rowSpan={2} className="border">
                      Join Status
                    </th>
                    <th rowSpan={2} className="border">
                      Response Date
                    </th>
                    <th rowSpan={2} className="border">
                      Created Date
                    </th>
                    <th rowSpan={2} className="border">
                      Remarks
                    </th>
                    <th rowSpan={2} className="border">
                      More
                    </th>
                  </tr>
                  <tr>
                    <th className="border">Student ID</th>
                    <th className="border">Email</th>
                    <th className="border">Name</th>
                    <th className="border">Phone</th>
                  </tr>
                </thead>
                <tbody>
                  {placementData?.map((item, index) => (
                    <tr key={index}>
                      <td>{index}</td>
                      <td>{item.applicationID}</td>
                      <td>{item.recruiter.empId}</td>
                      <td>{item.placementDetails.placementId}</td>
                      <td>{item.jobDescription.jobId}</td>
                      <td>{item.candidate.stuId}</td>
                      <td>{item.candidate.email}</td>
                      <td>{item.candidate.name}</td>
                      <td>{item.candidate.phone}</td>
                      <td>{item.placementDetails.joiningDate}</td>
                      <td>{item.placementDetails.noticePeriodStartDate}</td>
                      <td>{item.placementDetails.noticePeriodEndDate}</td>
                      <td>{item.placementDetails.recordedDate}</td>
                      <td>
                        <span
                          className={`w-4 h-4 inline-block rounded-full ${
                            item.placementDetails.isJoined
                              ? "bg-green-500"
                              : "bg-red-500"
                          }`}
                        />
                      </td>
                      <td>{item.placementDetails.remarkedOn}</td>

                      <td>{formatDate(item?.createdAt)}</td>
                      <td>{item?.offerDetails?.remarks}</td>
                      <td>
                        <PopoverPlacement item={item} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="relative z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
        <div className="relative z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-10" />
        <div className="relative z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
      </div>
    </Fragment>
  );
};
