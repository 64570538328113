import React, { useEffect } from "react";
import styles from "../../Styles";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { useState } from "react";
import { json } from "react-router-dom";

const EditForm = ({ setShowForm, isID }) => {
  // const [isHide, setHide] = useState(false);
  // console.log(isID);
  const [data, setData] = useState({});
  const [formdata, setFormData] = useState({});

  const handleHide = () => {
    setShowForm(false);
  };

  // const initialValues = {
  //     candidate_name: '',
  //     email: '',
  //     phnum: '',
  //     skill_list: ''

  // };
  useEffect(() => {
    fetch(` http://localhost:8000/data/${isID}`)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setData(data);
        setFormData(data);
      });
  }, []);

  const handleSubmit = (values) => {
    fetch(`http://localhost:8000/data/${isID}`, {
      method: "PUT",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((updatedData) => {
        console.log(data);
        setData(updatedData);
      })
      .then(window.location.reload());
  };

  return (
    <div className="Edit_Form">
      {/* <div className={` bg-black overflow-hidden w-full  h-[1280px] pipeline `}>
            <div className={`${styles.paddingX} ${styles.flexCenter} z-[3]`}>
                <div className={`${styles.boxWidth} items-center justify-items-center`}> */}
      <div className="form_content">
        <div
          className={`text-white font-poppins text-[16px] border-2 mt-10 h-fit bg-blue-900 shadow-lg shadow-blue-500  items-center overflow-hidden w-[45rem]`}
        >
          <img
            className={`float-right`}
            width="32"
            height="32"
            onClick={handleHide}
            src="https://img.icons8.com/windows/32/FFFFFF/delete-sign.png"
            alt="delete-sign"
          />

          <Formik
            initialValues={formdata}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            <Form className="Login-form">
              <div className="state-dist"></div>
              <div className="form_row">
                <div className="form-divider">
                  <label htmlFor="candidate_name">
                    Candidate Name
                    <Field
                      type="text"
                      id="candidate_name"
                      placeholder="Enter your candidate name"
                      name="candidate_name"
                    />
                    <ErrorMessage
                      name="candidate_name"
                      className="error"
                      component="div"
                    />
                  </label>

                  <label htmlFor="email">
                    {" "}
                    Email
                    <Field
                      type="email"
                      id="email"
                      placeholder="Youremail@gmail.com"
                      name="email"
                    />
                    <ErrorMessage
                      name="email"
                      className="error"
                      component="div"
                    />
                  </label>
                  <label htmlFor="alt_email">
                    {" "}
                    Alternate Email
                    <Field
                      type="email"
                      id="alt_email"
                      placeholder="Youremail@gmail.com"
                      name="alt_email"
                    />
                    <ErrorMessage
                      name="alt_email"
                      className="error"
                      component="div"
                    />
                  </label>
                  <label htmlFor="pan_no">
                    {" "}
                    PAN NO.
                    <Field
                      type="text"
                      id="pan_no"
                      placeholder="Enter your first name"
                      name="pan_no"
                    />
                    <ErrorMessage
                      name="pan_no"
                      className="error"
                      component="div"
                    />
                  </label>
                  <label htmlFor="current_location ">
                    Current Location
                    <Field
                      type="text"
                      id="current_location"
                      placeholder="Enter your current location"
                      name="current_location"
                    />
                    <ErrorMessage
                      name="current_location"
                      className="error"
                      component="div"
                    />
                  </label>
                  <label htmlFor="current_company">
                    Current Company
                    <Field
                      type="text"
                      id="current_company"
                      placeholder="Enter your current company"
                      name="current_company"
                    />
                    <ErrorMessage
                      name="current_company"
                      className="error"
                      component="div"
                    />
                  </label>
                  <label htmlFor="YOE">
                    {" "}
                    Year Of Experience
                    <Field
                      type="number"
                      id="YOE"
                      placeholder="Enter your year of Experience"
                      name="YOE"
                    />
                    <ErrorMessage
                      name="YOE"
                      className="error"
                      component="div"
                    />
                  </label>
                  <label htmlFor="current_ctc">
                    {" "}
                    Current CTC
                    <Field
                      type="number"
                      id="current_ctc"
                      placeholder="Enter your current ctc"
                      name="current_ctc"
                    />
                    <ErrorMessage
                      name="current_ctc"
                      className="error"
                      component="div"
                    />
                  </label>
                  <label htmlFor="skill_list">
                    Any Certification
                    <Field
                      as="textarea"
                      id="certification"
                      name="certification"
                      className=" h-[3rem] text-black mt-4 mb-4 rounded"
                      placeholder="Add any certification  here"
                    />
                  </label>
                  <label htmlFor="comments">
                    {" "}
                    Add Comments:
                    <Field
                      as="textarea"
                      id="comments"
                      name="comments"
                      className=" h-[3rem] text-black mt-4 mb-4 rounded"
                      placeholder="Add any comments here"
                    />
                  </label>
                </div>
                <div className="form-divider">
                  <label htmlFor="dob">
                    {" "}
                    DOB
                    <Field
                      type="number"
                      id="dob"
                      placeholder="Enter your DOB"
                      name="dob"
                    />
                    <ErrorMessage
                      name="dob"
                      className="error"
                      component="div"
                    />
                  </label>
                  <label htmlFor="adhara_card">
                    {" "}
                    Adhara card
                    <Field
                      type="number"
                      id="adhara_card"
                      placeholder="Enter your phone number"
                      name="adhara_card"
                    />
                    <ErrorMessage
                      name="adhara_card"
                      className="error"
                      component="div"
                    />
                  </label>

                  <label htmlFor="phnum">
                    {" "}
                    Phone no.
                    <Field
                      type="number"
                      id="phnum"
                      placeholder="Enter your phone number"
                      name="phnum"
                    />
                    <ErrorMessage
                      name="phnum"
                      className="error"
                      component="div"
                    />
                  </label>
                  <label htmlFor="alt_phnum">
                    {" "}
                    Alternate Phone no.
                    <Field
                      type="number"
                      id="alt_phnum"
                      placeholder="Enter your alternate phone num"
                      name="alt_phnum"
                    />
                    <ErrorMessage
                      name="alt_phnum"
                      className="error"
                      component="div"
                    />
                  </label>
                  <label htmlFor="expected_location">
                    Expected Location
                    <Field
                      type="text"
                      id="expected_location"
                      placeholder="Enter your expected location"
                      name="expected_location"
                    />
                    <ErrorMessage
                      name="expected_location"
                      className="error"
                      component="div"
                    />
                  </label>
                  <label htmlFor="current_designation">
                    Current Designation
                    <Field
                      type="text"
                      id="current_designation"
                      placeholder="Enter your current designation"
                      name="current_designation"
                    />
                    <ErrorMessage
                      name="current_designation"
                      className="error"
                      component="div"
                    />
                  </label>
                  <label htmlFor="notice_period">
                    {" "}
                    Notice Period
                    <Field
                      type="text"
                      id="notice_period"
                      placeholder="Enter your notice period"
                      name="notice_period"
                    />
                    <ErrorMessage
                      name="notice_period"
                      className="error"
                      component="div"
                    />
                  </label>
                  <label htmlFor="expected_ctc">
                    {" "}
                    Expected CTC
                    <Field
                      type="number"
                      id="expected_ctc"
                      placeholder="Enter your Expected ctc"
                      name="expected_ctc"
                    />
                    <ErrorMessage
                      name="expected_ctc"
                      className="error"
                      component="div"
                    />
                  </label>
                  <label htmlFor="high_qualification">
                    {" "}
                    Highest Qualification
                    <Field
                      type="text"
                      id="high_qualification"
                      placeholder="Enter your highest qualification"
                      name="high_qualification"
                    />
                    <ErrorMessage
                      name="high_qualification"
                      className="error"
                      component="div"
                    />
                  </label>

                  <label htmlFor="skill_list">
                    Skill List:
                    <Field
                      as="textarea"
                      id="skill_list"
                      name="skill_list"
                      className=" h-[3rem] text-black mt-4 mb-4 rounded"
                      placeholder="Add any skills here"
                    />
                  </label>
                </div>
              </div>
              <button
                type="submit"
                className="text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 shadow-lg shadow-cyan-500/50 dark:shadow-lg dark:shadow-cyan-800/80 rounded-lg text-xl px-4 py-2.5 text-center mr-2 mb-2 font-semibold"
              >
                Submit
              </button>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
    // </div>
    // </div>
  );
};

export default EditForm;
