import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import styles from "../../Styles"
// import {close,menu,logo2} from "../assets"
import { logo2, close, menu } from '../../assets'
export const RecruiterNewHeader = ({ name, setName }) => {
    useEffect(() => {
        if (name) setName('Kushal')
    }, [name])
    const [toggle, setToggle] = useState(false);

    return (
        <div className={` bg-black  w-full h-fit `}>
            <div className={`${styles.paddingX} ${styles.flexCenter} z-[3] `}>
                <div className={`${styles.boxWidth} items-center `}>
                    <nav className="w-full flex py-6 justify-between items-center navbar">
                        <img src={logo2} alt="Daya-Consultancy" className="w-[150px] h-[60px] brand" />
                        <ul className="list-none sm:flex hidden justify-end items-center flex-1 z-[4]">
                            <li class="nav-item text-white ">
                                <Link className={`nav-link active font-poppins font-normal cursor-pointer text-[16px] `} aria-current="page" >Name</Link>
                            </li>
                            <li class="nav-item">
                                <Link className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px] mx-[20px]`} to="/candidate" >Home</Link>
                            </li>
                            <li class="nav-item">
                                <Link className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px]`} to="/" >Logout</Link>
                            </li>
                        </ul>

                        <div className="sm:hidden flex flex-1 justify-end items-center">
                            <img
                                src={toggle ? close : menu}
                                alt="menu"
                                className="w-[28px] h-[28px] object-contain"
                                onClick={() => setToggle(!toggle)}
                            />

                            <div
                                className={`${!toggle ? "hidden" : "flex"
                                    } p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}
                            >
                                <ul className="list-none flex justify-end items-start flex-1 flex-col">
                                    <li class="nav-item ">
                                        <Link className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px]`} aria-current="page" to="/">Name</Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px]`} to='/candidate'>Home</Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link className={`nav-link active text-white font-poppins font-normal cursor-pointer text-[16px]`} to="/">Logout</Link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}
