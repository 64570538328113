import axios from "axios";
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useAuth } from "./AuthContext";
import toast from "react-hot-toast";
import { useEnv } from "./EnvContext";
import { sortTransactionsByDate } from "../Utils/ExtraFun";

const CandidateContext = createContext();

export const CandidateContextProvider = ({ children }) => {
  const { backendUrl } = useEnv();
  const { userData } = useAuth();

  const [allCandidates, setAllCandidates] = useState();

  const [candidateData, setCandidateData] = useState([]);

  const addCandidate = useCallback(
    async (values) => {
      console.log("addCandidateValues", values);
      let loadingToast;
      const token = userData.token;
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      try {
        loadingToast = toast.loading("Adding Candidate ...", {
          position: "top-right",
          style: {
            marginTop: "62px",
          },
        });

        const res = await axios.post(`${backendUrl}/candidate/add`, values, {
          headers,
        });
        console.log("res.data.message", res.data);
        toast.success(res.data.message, {
          position: "top-right",
          style: {
            marginTop: "62px",
          },
        });
      } catch (err) {
        console.log(err);
        const errorMessage =
          err.response?.data?.message || "Something went wrong";
        toast.error(errorMessage, {
          position: "top-right",
          style: {
            marginTop: "62px",
          },
        });
      } finally {
        if (loadingToast) {
          toast.dismiss(loadingToast);
        }
      }
    },
    [backendUrl, userData.token]
  );

  const updateCandidate = useCallback(
    async (values, id) => {
      console.log("addCandidateValues", values);
      let loadingToast;
      const token = userData.token;
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      try {
        loadingToast = toast.loading("Update Candidate ...", {
          position: "top-right",
          style: {
            marginTop: "62px",
          },
        });

        const res = await axios.put(
          `${backendUrl}/candidate/update?candidateId=${id}`,
          values,
          { headers }
        );
        console.log("res.data.message", res.data);
        toast.success(res.data.message, {
          position: "top-right",
          style: {
            marginTop: "62px",
          },
        });
      } catch (err) {
        console.log(err);
        const errorMessage =
          err.response?.data?.message || "Something went wrong";
        toast.error(errorMessage, {
          position: "top-right",
          style: {
            marginTop: "62px",
          },
        });
      } finally {
        if (loadingToast) {
          toast.dismiss(loadingToast);
        }
      }
    },
    [backendUrl, userData.token]
  );

  const getAllCandidate = useCallback(async () => {
    console.log("getAllCandidate function is called in candidate page");
    let loadingToast;
    const token = userData?.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      loadingToast = toast.loading("Fetching all Candidate ...", {
        position: "top-right",
        style: {
          marginTop: "62px",
        },
      });

      const res = await axios.get(`${backendUrl}/candidate/all`, { headers });
      const data = sortTransactionsByDate(res.data.candidates);
      console.log("data:", data);
      setAllCandidates(data);
      console.log("All candidate in candidateContextProvider", allCandidates);
      toast.success("Successfully fetched", { position: "top-right" });
    } catch (err) {
      console.log(err);
      const errorMessage = err.message || "Something went wrong";
      toast.error(errorMessage, {
        position: "top-right",
        style: {
          marginTop: "62px",
        },
      });
    } finally {
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  }, [backendUrl, userData.token]);

  const getCandidate = useCallback(
    async (id) => {
      console.log("candidateId", id);
      const token = userData.token;
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      try {
        const res = await axios.get(
          `${backendUrl}/candidate?candidateId=${id}`,
          { headers }
        );
        console.log(res.data.candidate);
        setCandidateData(res.data.candidate);
      } catch (err) {
        console.log(err);
      }
    },
    [backendUrl, userData.token]
  );

  useEffect(() => {
    if (backendUrl && userData.token) {
      getAllCandidate();
    }
  }, [backendUrl, userData.token, getAllCandidate]);

  const contextValue = useMemo(
    () => ({
      addCandidate,
      getAllCandidate,
      allCandidates,
      getCandidate,
      candidateData,
      updateCandidate,
    }),
    [
      addCandidate,
      getAllCandidate,
      allCandidates,
      getCandidate,
      candidateData,
      updateCandidate,
    ]
  );

  return (
    <CandidateContext.Provider value={contextValue}>
      {children}
    </CandidateContext.Provider>
  );
};

export default CandidateContext;

export const useCandidate = () => useContext(CandidateContext);
