import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";
import styles from "../../Styles";
import { RecruimentHeader } from "../component/RecruimentHeader";
import { RecruitmentSubHeader } from "../component/RecruitmentSubHeader";
import { PipelineContext } from "../../context/PipelineContext";
import { useAuth } from "../../context/AuthContext";
import { FaWhatsapp } from "react-icons/fa";
import AdminSubheader from "../../admin/components/AdminSubheader";
import Header from "../../admin/components/Header";
import PipelineMenu from "../component/modal/PipelineMenu";
import FilterMenu from "../component/modal/FilterMenu";
import RevertMenu from "../component/modal/RevertMenu";
import CVprocesMenu from "../component/modal/CVprocesMenu";
import { formatDate } from "../../Utils/ExtraFun";
import { TableContainer } from "@chakra-ui/react";
export const Pipelines = () => {
  const { handleApplicationForm, fetchedData, forwardCv } =
    useContext(PipelineContext);
  const { userData } = useAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(fetchedData);
  const [openIndex, setOpenIndex] = useState(null);
  const moreDetailsRef = useRef(null);

  //for search functionality
  useEffect(() => {
    const filterApplication = fetchedData.filter(
      (data) =>
        data.applicationStatus !== "Rejected" &&
        data.currentState !== "CV Selected" &&
        // data.interviewDetails.length === 0 &&
        data.offerProcess !== "On Processing" &&
        (data.cvProcess === "Not Started" || data.cvProcess === "On Process")
    );

    console.log("filter student:", filterApplication);
    setFilteredData(filterApplication);
    setOpenIndex(null);
  }, [fetchedData]);

  const handleSearch = () => {
    if (Array.isArray(fetchedData)) {
      const query = searchQuery.toLowerCase();
      const newFilteredData = fetchedData.filter((item) => {
        const fields = [
          item?.applicationID,
          item?.applicationStatus,
          item?.jobDescription?.jobId,
          item?.recruiter?.empId,
          item?.candidate?.stuId,
          item?.candidate?.email,
          item?.candidate?.name,
          item?.candidate?.phone,
          item?.currentState,
          item?.cvProcess,
          item?.cvSelectionDetails?.processId,
          item?.placementDetails?.PlacementId,
          item?.placementProcess,
        ];

        return fields.some((field) =>
          (field?.toLowerCase() || "").includes(query)
        );
      });

      setFilteredData(newFilteredData);
      setOpenIndex(null);
      setSearchQuery("");
    } else {
      console.warn("fetchedData is not an array or is undefined", fetchedData);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    if (userData.token) {
      handleApplicationForm();
    }
  }, [userData.token]);

  const moreDetails = (index) => {
    console.log("More icon is clicked for index:", index);
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        moreDetailsRef.current &&
        !moreDetailsRef.current.contains(event.target)
      ) {
        setOpenIndex(null); // Close more details if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const pipelineStatuses = ["Not Started", "On Process"];

  return (
    <Fragment>
      {userData.userType === "Recruiter" ? <RecruimentHeader /> : <Header />}
      <AdminSubheader />
      <div className={` bg-black overflow-hidden w-full h-fit pipeline`}>
        <div className={`${styles.paddingX} ${styles.flexCenter} z-[3]`}>
          <div className={`${styles.boxWidth} item?s-center`}>
            <h1 className="font-poppins text-blue-500 text-4xl shadow-lg shadow-blue-500 mt-3 text-center">
              Pipelines
            </h1>
            <div class="flex justify-between items-center border-2 0 bg-blue-900 shadow-lg shadow-blue-500 mt-5  rounded-2xl">
              <FilterMenu
                fetchedData={fetchedData}
                setFilteredData={setFilteredData}
                filteredData={filteredData}
              />
              <CVprocesMenu
                fetchedData={fetchedData}
                setFilteredData={setFilteredData}
                filteredData={filteredData}
              />
              <div className=" justify-item?s-end flex1">
                <div className="input-group relative flex flex-row  justify-end item?s-stretch w-full mb-4 mr-4 ">
                  <input
                    type="search"
                    className="form-control relative flex-auto min-w-0 block w-full h-[44px] px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none mt-3"
                    placeholder="Search Filters"
                    aria-label="Search"
                    aria-describedby="button-addon3"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={handleKeyPress}
                  />

                  <button
                    class=" mr-5 ml-2 mt-2 btn inline-block px-6 py-2 border-2 border-white text-white font-poppins text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                    type="button"
                    id="button-addon3"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>

            <div
              className={`text-white  font-poppins text-[16px] border-2 mt-4 max-h-[80vh] bg-blue-900  shadow-lg w-[full] shadow-blue-500  overflow-scroll scrollbar-hidden  items-center ${styles.boxWidth}`}
              style={{
                scrollbarWidth: "thin",
              }}
            >
              {/*firefox scrollbar styling*/}
              <style>
                {`.text-white::-webkit-scrollbar{
                  width:12px;}
                  .text-white::-webkit-scrollbar-thumb{
                  background-color:blue;
                  border-radius:10px;
                  }
                  .text-white::-webkit-scrollbar-track{
                  background:#ffffff00}`}
              </style>
              <TableContainer
                style={{
                  scrollbarWidth: "thin",
                }}
              >
                <table
                  className="table-auto text-center z-[1]"
                  id="table-to-xls"
                >
                  <thead className="border-b">
                    <tr>
                      <th rowSpan={"2"} className="border">
                        S.N
                      </th>
                      <th rowSpan="2" className="border">
                        Application ID
                      </th>
                      <th rowSpan="2" className="border">
                        Application Status
                      </th>
                      <th colSpan="1" className="border">
                        Job Description
                      </th>
                      <th colSpan="1" className="border">
                        Recruiter
                      </th>
                      <th colSpan="4" className="border">
                        Candidate
                      </th>

                      <th rowSpan="2" className="border">
                        Current State
                      </th>
                      <th rowSpan="2" className="border">
                        CV Process
                      </th>
                      <th rowSpan="2" className="border">
                        CV Selection Details
                      </th>
                      <th rowSpan="2" className="border">
                        Created Date
                      </th>
                      {userData.userType === "Recruiter" && (
                        <th rowSpan="2" className="border">
                          More
                        </th>
                      )}
                    </tr>
                    <tr>
                      <th className="border">Job ID</th>
                      <th className="border">Emp ID</th>
                      <th className="border">Student ID</th>
                      <th className="border">Email</th>
                      <th className="border">Name</th>
                      <th className="border">Phone</th>
                    </tr>
                  </thead>

                  <tbody>
                    {filteredData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item?.applicationID}</td>
                        <td>{item?.applicationStatus}</td>
                        <td>{item?.jobDescription.jobId}</td>
                        <td>{item?.recruiter.empId}</td>
                        <td>{item?.candidate.stuId}</td>
                        <td>{item?.candidate.email}</td>
                        <td>{item?.candidate.name}</td>
                        <td className="flex border  h-20 justify-center items-center gap-3 ">
                          <a
                            href={`https://wa.me/${item?.candidate.phone}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center border-none"
                          >
                            <FaWhatsapp className="bg-green-500 text-lg rounded-lg border-none" />
                          </a>
                          {item?.candidate.phone}
                        </td>
                        <td>{item?.currentState}</td>
                        <td>{item?.cvProcess}</td>
                        <td>{item?.cvSelectionDetails.processId}</td>
                        <td>{formatDate(item?.createdAt)}</td>
                        {userData.userType === "Recruiter" && (
                          <td>
                            {pipelineStatuses.includes(
                              item.applicationStatus
                            ) ? (
                              <PipelineMenu item={item} />
                            ) : item.applicationStatus === "Rejected" ? (
                              <RevertMenu item={item} />
                            ) : null}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TableContainer>
              {filteredData.length === 0 && (
                <p className="text-white text-center mt-4">
                  No data found for the search query
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="relative z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
        <div className="relative z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-10" />
        <div className="relative z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
      </div>
    </Fragment>
  );
};
