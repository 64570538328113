import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { IoFilter } from "react-icons/io5";

const FilterMenu = ({ fetchedData, setFilteredData, filteredData }) => {
  
  const handleFilterChange = (filter) => {
    if (filter === "Pending") {
      const acceptedApplications = fetchedData.filter(
        (data) => data.applicationStatus === "Not Started"
      );
      console.log("Pending appliication:", acceptedApplications);
      setFilteredData(acceptedApplications);
    } else if (filter === "Rejected") {
      const rejectedApplications = fetchedData.filter(
        (data) => data.applicationStatus === "Rejected"
      );
      console.log("Rejected Application", rejectedApplications);
      setFilteredData(rejectedApplications);
    } else {
      setFilteredData(fetchedData);
      console.log("all", filteredData);
    }
  };

  return (
    <Menu>
      <MenuButton ml={"2rem"} as={Button} rightIcon={<IoFilter />}>
        Filter
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => handleFilterChange("All")}>
          All Application
        </MenuItem>
        <MenuItem onClick={() => handleFilterChange("Pending")}>
          Pending Application
        </MenuItem>
        <MenuItem onClick={() => handleFilterChange("Rejected")}>
          Rejected Application
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default FilterMenu;
