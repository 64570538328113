import React, { Fragment, useEffect, useState } from 'react';
import styles from '../../Styles';
import { Link, useSearchParams } from 'react-router-dom';
import Header from '../components/Header';
import AdminSubheader from '../components/AdminSubheader';
import EditForm from '../forms/EditForm';



export const AdminCandudate = () => {
  const [showForm, setShowForm] = useState(false);
  const [minExpeerience, setMinExperience] = useState('');
  const [maxExperience, setMaxExperience] = useState('');
  const [name, setName] = useState('');
  const [data, setData] = useState([]);
  const [isId, setId] = useState(0);
  // console.log("Id is" + isId)

  useEffect(() => {
    fetch(" http://localhost:3000/data")
      .then(response => response.json())
      .then((data) => {
        setData(data);
      });

  }, [])
  const handelEdit = (item) => {
    setId(item.id);
    setShowForm(!showForm)
  }

  const handelSearch = () => {
    if (name) {
      setData(data.filter((item) => item.CandidateName.toLowerCase().include(name.toLocaleLowerCase())))
      alert("name")
    }
    else if (minExpeerience && maxExperience === "") {
      setData(data.filter((item) => item.YearsofExp >= minExpeerience))
    }
    else if (maxExperience && minExpeerience === "") {
      setData(data.filter((item) => item.YearsofExp <= maxExperience))
    }

    else if (minExpeerience && maxExperience) {
      setData(data.filter((item) => item.YearsofExp >= minExpeerience && item.YearsofExp <= maxExperience))
    }
    else alert('Not Found')
  }
  return (
    <Fragment>
      <Header />
      <AdminSubheader />

      <div className={` bg-black overflow-hidden w-full  h-fit pipeline `}>
        <div className={`${styles.paddingX} ${styles.flexCenter} z-[3]`}>
          <div className={`${styles.boxWidth} items-center justify-items-center`}>

            <h1 className='font-poppins text-blue-500 text-4xl shadow-lg shadow-blue-500 mt-3 text-center'>Candidates</h1>

            <div className={`mt-[25px]  font-poppins font-normal cursor-pointer text-center flex flex-row justify-around `}>
              <select name="candidate-list" id="candidate-list" className={`text-center float-left justify-center border-2 w-[full] h-fit  bg-blue-900 text-white shadow-lg shadow-blue-500 `}>
                <option value="My-Candidates" className=
                  {`text-white`}>My-Candidates
                </option>
                <option value="All-Candidates" className=
                  {`text-white`}>All-Candidates</option>
              </select>



              <div className={`flex  bg-blue-900 flex-wrap cursor-pointer shadow-lg shadow-blue-500  border-2 rounded-2xl h-[110px] w-[500px] `}>

                <label className={`mt-1 mx-3 text-white font-poppins text-[16px] underline my-[5px]`} htmlFor="requirements">My Active Requirements</label>

                <div className=' flex flex-col items-center'>
                  <select name="my-active-requirements" id="my-active-requirements" className={` relative text-center  w-[full ]  flex-wrap h-[30px]  text-white bg-blue-800 ${styles.boxWidth}`}>
                    <option value="none" className=
                      {`text-white text-center   `}>None
                    </option>
                    <option className=
                      {`text-white text-center`}>JB-16236--Sales Manager-VRM Manager Bancassurance HDFC Bank</option>
                    <option className=
                      {`text-white text-center`}>JB-15835--PMO- Performance Management Officer</option>
                    <option className=
                      {`text-white`}>JB-15387--Telesales(B2C)-Life and Helath insurance</option>
                    <option className=
                      {`text-white`}>JB-15390--Relationship Manager(Life)</option>
                    <option className=
                      {`text-white`}>JB-15388--Relationship Manager(Health)</option>
                    <option className=
                      {`text-white`}>JB-15389--Relationship Manager(Motor)</option>
                    <option className=
                      {`text-white`}>JB-16126--.Net Core Plus Angular Senior software developer(MyGate)</option>
                    <option className=
                      {`text-white`}>JB-16124--Angular Plus Node JS Software Developer(MyGate)</option>
                    <option className=
                      {`text-white`}>JB-16125--.Net Core plus Angular software Developer(My Gate)</option>
                    <option className=
                      {`text-white`}>JB-15548--Adobe Analytics Specialist</option>
                    <option className=
                      {`text-white`}>JB-14742--.Net Developer</option>
                    <option className=
                      {`text-white`}>JB-16185--Cluster Management-Bancassurance Channel</option>
                    <option className=
                      {`text-white`}>JB-16227--Territory Manager-National Pension Scheme</option>
                    <option className=
                      {`text-white`}>JB-14310--Java Developer</option>
                  </select>
                  <button className='w-[full] justify-center text-center flex flex-wrap  border-[0.5px]  mt-[10px] rounded-2xl '>
                    <Link className={`text-white w-[280px] h-[25px] rounded-2xl `} to="#">Add Candidate to Requirement</Link>
                  </button></div>



              </div>
            </div>


            <div className="flex h-[60px]  w-[full] justify-center border-2  bg-blue-900 shadow-lg shadow-blue-500 mt-[60px] ">
              <div className="input-group mx-2 flex flex-1 w-[400px] mb-4 space-x-1.5">
                <label className=' mt-1 text-white font-poppins text-[16px] mr-[10px]' htmlFor="search">Keyword Serach</label>
                <input type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control relative flex min-w-0 mt-[10px]  w-full  px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder="Search" aria-label="Search" aria-describedby="button-addon3" />
                <label className=' mt-1 text-white font-poppins text-[16px]' htmlFor="search">Min Exp(Years)</label>
                <input type="number"
                  value={minExpeerience}
                  onChange={(e) => setMinExperience(e.target.value)}
                  className="form-control relative flex min-w-0 mt-[10px] w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder="Search" aria-label="Search" aria-describedby="button-addon3" />
                <label className=' mt-1 text-white font-poppins text-[16px]' htmlFor="search">Max Exp(Years)</label>
                <input type="number"
                  value={maxExperience}
                  onChange={(e) => setMaxExperience(e.target.value)}
                  className="form-control relative flex min-w-0 mt-[10px] w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder="Search" aria-label="Search" aria-describedby="button-addon3" />
                <button className=" mt-2 btn  px-5 py-2  border-2 border-white text-white  font-poppins text-xs  uppercase  rounded-xl  hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out" type="button" id="button-addon3" onClick={handelSearch} >Search</button>
              </div>
            </div>
            {/* <div className="flex justify-center border-2 h-[50px]  bg-blue-900 shadow-lg shadow-blue-500 mt-5 ">
          <label className=' mt-1 text-white font-poppins text-[16px]' htmlFor="search">Keyword Serach (Boolean AND / OR / NOT)</label>
              <input type="search" className="form-control relative  min-w-0 block w-[50px] px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder="Search" aria-label="Search" aria-describedby="button-addon3" />
             
          </div> */}
            {/* <div className={` bg-blue-500 h-[30px] w-[120px] shadow-lg border-[1px] shadow-blue-500 rounded-[10px]  text-white text-center mt-4 text-[16px]`}>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button"
              table="table-to-xls"
              filename="candidate-details"
              sheet="tablexls"
              buttonText="Export Excel" />
          </div> */}
            <div className={`text-white font-poppins text-[16px] border-2 mt-5 h-[500px]   bg-blue-900 shadow-lg shadow-blue-500  items-center `}>
              <table className="table-auto w-full text-center z-[1]" id="table-to-xls">
                <thead className='border-b'>
                  <th>Candidate Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Years of Exp</th>
                  <th>Skills</th>
                  <th>Edit</th>
                </thead>
                <tbody>
                  {data.map((item) => (
                    <tr>
                      <td>
                        <Link className=' underline' to="/CandidateDetails1">{item.candidate_name} </Link>
                      </td>
                      <td>{item.email}</td>
                      <td>{item.phnum}</td>
                      <td>{item.YearsofExp}</td>
                      <td>{item.skill_list}</td>
                      <td>
                        <a onClick={() => handelEdit(item)} className='float-right'>
                          <img width="25" height="25" src="https://img.icons8.com/fluency-systems-filled/48/FFFFFF/edit.png" alt="edit" />
                        </a>
                      </td>
                    </tr>
                  ))}

                </tbody>
              </table>
            </div>
            {
              showForm && <EditForm setShowForm={setShowForm} isID={isId} />
            }
          </div>
        </div>

        <div className="relative z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
        <div className="relative z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-10" />
        <div className="relative z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
      </div>
    </Fragment>
  )
}
