import React, { createContext, useCallback, useMemo } from "react";
import { useEnv } from "./EnvContext";
import { useAuth } from "./AuthContext";
import toast from "react-hot-toast";
import axios from "axios";
import { usePipeLine } from "./PipelineContext";

export const PlacementContex = createContext();
const PlacementContexProvider = ({ children }) => {
  const { backendUrl } = useEnv();
  const { userData } = useAuth();
  const { handleApplicationForm } = usePipeLine();

  //function for record placement api hit, memoized with useCallback hook
  const handleRecordPlacement = useCallback(
    async (values, applicationId) => {
      const token = userData?.token;
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      let loadingtoast;
      try {
        loadingtoast = toast.loading("Submit Data....", {
          position: "top-right",
          style: {
            marginTop: "80px",
          },
        });

        const response = await axios.put(
          `${backendUrl}/application/record_placement?applicationId=${applicationId}`,
          values,
          { headers }
        );
        const data = response.data;
        handleApplicationForm();
        toast.success(data.message, {
          position: "top-left",
          style: {
            marginTop: "65px",
          },
        });
      } catch (error) {
        if (error.response.data.message) {
          toast.error(`${error.response.data.message}`, {
            position: "top-left",
            style: {
              marginTop: "67px",
            },
          });
        } else {
          toast.error("Something Went wrong", {
            position: "top-right",
            style: {
              marginTop: "62px",
            },
          });
        }
      } finally {
        if (loadingtoast) {
          toast.dismiss(loadingtoast);
        }
      }
    },
    [backendUrl, userData?.token]
  );

  //function  for record placement update api hit, memoised value
  const handleRecordPlacementUpdate = useCallback(
    async (values, applicationId) => {
      console.log("the funcction is called");
      const token = userData?.token;
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      let loadingtoast;
      try {
        loadingtoast = toast.loading("submit record update...", {
          position: "top-right",
          style: {
            marginTop: "65px",
          },
        });

        const response = await axios.put(
          `${backendUrl}/application/record_placement_update?applicationId=${applicationId}`,
          values,
          { headers }
        );
        const data = response.data;
        handleApplicationForm();
        toast.success(data.message, {
          position: "top-right",
          style: {
            marginTop: "65px",
          },
        });
      } catch (error) {
        if (error.response.data.message) {
          toast.error(`${toast.response.data.message}`, {
            position: "top-right",
            style: {
              marginTop: "67px",
            },
          });
        } else {
          toast.error("Something went wrong", {
            position: "top-right",
            style: {
              marginTop: "68px",
            },
          });
        }
      } finally {
        if (loadingtoast) {
          toast.dismiss(loadingtoast);
        }
      }
    },
    [backendUrl, userData?.token]
  );

  const contextValue = useMemo(
    () => ({
      handleRecordPlacementUpdate,
      handleRecordPlacement,
    }),
    [handleRecordPlacementUpdate, handleRecordPlacement]
  );

  return (
    <PlacementContex.Provider value={contextValue}>
      {children}
    </PlacementContex.Provider>
  );
};

export default PlacementContexProvider;
