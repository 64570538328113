import React from 'react';
import styles from '../../Styles';
import { Link } from 'react-router-dom';
import { RecruiterNewHeader } from '../component/RecruiterNewHeader'
import { Form, Formik,Field,ErrorMessage } from 'formik';
import * as Yup from 'yup';
// import ReactDatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.module.css'
import { useState } from 'react';

const validationSchema = Yup.object().shape({
    first_name : Yup.string()
    .min(2,'Too short!')
    .max(20,'too Long!')
    .required('First name is required'),
    last_name : Yup.string()
    .min(2,'Too Short!')
    .max(2,'Too Long!')
    .required ('Last name is Required'),
    email: Yup.string()
    .email('Invalid email format')
    .required('email is required'),
    phnum: Yup.string()
    .matches(/^\d{10}$/, 'Phone number must be 10 digits')
    .required('Phone number is required'),
    current_location: Yup.string()
    .required('current location is required'),
    expected_location: Yup.string()
    .required('expected location is required'),
    current_company: Yup.string()
    .required('current company is required'),
    current_designation: Yup.string()
    .required('Current designation is required'),
    YOE:Yup.string()
    .required('Year Of Experiencer required'),
    notice_period: Yup.string()
    .required('notice period is required'),
    current_ctc: Yup.string()
    .required('current ctc is required'),
    expected_ctc:Yup.string()
    .required('expected ctc is required'),


}

)

const RunResumeParser = () => {
    const salutationOptions = ['Mr.','Mrs.','Miss'] ;
    const genderOptions = ['Male','Female' ,'Others']
    const sourceOptions = ['Instagram','LinkedIn','Facebook','Social Media','Others']
const initialValues = {
    first_name:'',
    last_name:'',
    email:'',
    alt_email:'',
    phnum:'',
    alt_phnum:'',
    salutation: '',
    gender: '',
    current_location:'',
    expected_location:'',
    current_company:'',
    current_designation:'',
    YOE:'',
    notice_period:'',
    current_ctc:'',
    expected_ctc:'',
    pan_no: '',
    middle_name: '',
    certification: '',
    adhara_card: '',
    high_qualification: '',
    dob: ''
};
const handelSubmit = (Values ,{resetForm}) =>{
    console.log(Values);
    resetForm();
}
const [selectDate,setSelectDate] = useState(null);


  return (
   <>
   <RecruiterNewHeader/>
   
   <div className={` bg-black overflow-hidden w-full  h-[1280px] pipeline `}>
        <div className={`${styles.paddingX} ${styles.flexCenter} z-[3]`}>
          <div className={`${styles.boxWidth} items-center justify-items-center`}>
            <h1 className='font-poppins text-blue-500 text-4xl shadow-lg shadow-blue-500 mt-3 text-center'> Candidate Details</h1>



            <div className={`text-white font-poppins text-[16px] border-2 mt-10 h-fit bg-blue-900 shadow-lg shadow-blue-500  items-center overflow-hidden ${styles.boxWidth}`}>
            <Formik
    initialValues ={initialValues}
    validationSchema ={validationSchema}
    onSubmit ={handelSubmit}>
  
    <Form className="Login-form">
                  <div className='state-dist'>
                    <label htmlFor="source">Source:
                      <Field as="select" name="source" className="User-DropDown">
                        {sourceOptions.map(option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Field>
                    </label>
                    <label htmlFor="gender">Gender:
                      <Field as="select" name="gender" className="User-DropDown">
                        {genderOptions.map(option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Field>
                    </label>
                    <label htmlFor="salutation">Salutation:
                      <Field as="select" name="salutation" className="User-DropDown">
                        {salutationOptions.map(option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Field>
                    </label>

                  </div>
                  <div className="form_row">
                    <div className="form-divider">

                      <label htmlFor="first_name">First Name
                        <Field type="text" id="first_name" placeholder="Enter your first name" name="first_name" />
                        <ErrorMessage name="first_name" className='error' component="div" />
                      </label>
                      <label htmlFor="middle_name">Middle Name
                        <Field type="text" id="middle_name" placeholder="Enter your first name" name="middle_name" />
                        <ErrorMessage name="middle_name" className='error' component="div" />
                      </label>

                      <label htmlFor="email"> Email
                        <Field type="email" id="email" placeholder="Youremail@gmail.com" name="email" />
                        <ErrorMessage name="email" className='error' component="div" />
                      </label>
                      <label htmlFor="alt_email"> Alternate Email
                        <Field type="email" id="alt_email" placeholder="Youremail@gmail.com" name="alt_email" />
                        <ErrorMessage name="alt_email" className='error' component="div" />
                      </label>
                      <label htmlFor="pan_no"> PAN NO.
                        <Field type="text" id="pan_no" placeholder="Enter your first name" name="pan_no" />
                        <ErrorMessage name="pan_no" className='error' component="div" />
                      </label>
                      <label htmlFor="current_location ">Current Location
                      <Field type="text" id="current_location" placeholder="Enter your current location" name="current_location" />
                        <ErrorMessage name="current_location" className='error' component="div" />
                        </label>
                        <label htmlFor="current_company">Current Company
                      <Field type="text" id="current_company" placeholder="Enter your current company" name="current_company" />
                        <ErrorMessage name="current_company" className='error' component="div" />
                        </label>
                        <label htmlFor="YOE">  Year Of Experience
                        <Field type="number" id="YOE" placeholder="Enter your year of Experience" name="YOE" />
                        <ErrorMessage name="YOE" className='error' component="div" />
                      </label>
                      <label htmlFor="current_ctc"> Current CTC
                        <Field type="number" id="current_ctc" placeholder="Enter your current ctc" name="current_ctc" />
                        <ErrorMessage name="current_ctc" className='error' component="div" />
                      </label>
                      <label htmlFor="skill_list">Any Certification
                        <Field
                          as="textarea"
                          id="certification"
                          name="certification"
                          className=' h-[3rem] text-black mt-4 mb-4 rounded'
                          placeholder='Add any certification  here'
                        />
                      </label>
                      <label htmlFor="comments"> Add Comments:
                        <Field
                          as="textarea"
                          id="comments"
                          name="comments"
                          className=' h-[3rem] text-black mt-4 mb-4 rounded'
                          placeholder='Add any comments here'
                        />
                      </label>
                    </div>
                    <div className="form-divider">
                      <label htmlFor="last_name">Last Name
                        <Field type="text" id="last_name" placeholder="Enter your last name" name="last_name" />
                        <ErrorMessage name="last_name" className='error' component="div" />
                      </label>
                      <label htmlFor="dob"> DOB
                        <Field type="number" id="dob" placeholder="Enter your DOB" name="dob" />
                       
                        
                        <ErrorMessage name="dob" className='error' component="div" />
                      </label>
                      <label htmlFor="adhara_card"> Adhara card
                        <Field type="number" id="adhara_card" placeholder="Enter your phone number" name="adhara_card" />
                        <ErrorMessage name="adhara_card" className='error' component="div" />
                      </label>
                      
                      <label htmlFor="phnum"> Phone no.
                        <Field type="number" id="phnum" placeholder="Enter your phone number" name="phnum" />
                        <ErrorMessage name="phnum" className='error' component="div" />
                      </label>
                      <label htmlFor="alt_phnum"> Alternate Phone no.
                        <Field type="number" id="alt_phnum" placeholder="Enter your alternate phone num" name="alt_phnum" />
                        <ErrorMessage name="alt_phnum" className='error' component="div" />
                      </label>
                      <label htmlFor="expected_location">Expected Location
                      <Field type="text" id="expected_location" placeholder="Enter your expected location" name="expected_location" />
                        <ErrorMessage name="expected_location" className='error' component="div" />
                        </label>
                        <label htmlFor="current_designation">Current Designation
                      <Field type="text" id="current_designation" placeholder="Enter your current designation" name="current_designation" />
                        <ErrorMessage name="current_designation" className='error' component="div" />
                        </label>
                        <label htmlFor="notice_period"> Notice Period
                        <Field type="text" id="notice_period" placeholder="Enter your notice period" name="notice_period" />
                        <ErrorMessage name="notice_period" className='error' component="div" />
                      </label>
                      <label htmlFor="expected_ctc"> Expected CTC
                        <Field type="number" id="expected_ctc" placeholder="Enter your Expected ctc" name="expected_ctc" />
                        <ErrorMessage name="expected_ctc" className='error' component="div" />
                      </label>
                      <label htmlFor="high_qualification"> Highest Qualification 
                        <Field type="text" id="high_qualification" placeholder="Enter your highest qualification" name="high_qualification" />
                        <ErrorMessage name="high_qualification" className='error' component="div" />
                      </label>
                        
                      <label htmlFor="skill_list">Skill List:
                        <Field
                          as="textarea"
                          id="skill_list"
                          name="skill_list"
                          className=' h-[3rem] text-black mt-4 mb-4 rounded'
                          placeholder='Add any skills here'
                        />
                      </label>
                    </div>
                    
                  </div>
                  
                  <button type="submit" className="text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 shadow-lg shadow-cyan-500/50 dark:shadow-lg dark:shadow-cyan-800/80 rounded-lg text-xl px-4 py-2.5 text-center mr-2 mb-2 font-semibold">Submit</button>
                </Form>
   
</Formik>
            </div>
          </div>
        </div>
      </div>
   </>
  )
}

export default RunResumeParser
