import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ChakraProvider,
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Button,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";

import axios from "axios";
import EnvContext from "../../../context/EnvContext";
import { useAuth } from "../../../context/AuthContext";

export const UpdateDetailsModal = ({ children }) => {
  const { userData } = useAuth();
  const { backendUrl } = useContext(EnvContext);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [countryCode, setCountryCode] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [whatsAppNo, setWhatsAppNo] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dp, setDp] = useState(null);
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("countryCode", countryCode);
    formData.append("phoneNo", phoneNo);
    formData.append("whatsAppNo", whatsAppNo);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    if (dp) formData.append("dp", dp);
    const token = userData.token;

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };
    try {
      const response = await axios.post(
        `${backendUrl}/user/update_user_details?userId=${userData.userId}`,
        formData,
        {
          headers,
        }
      );
      toast({
        title: "User details updated.",
        description: "The user details have been successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "An error occurred.",
        description: "Unable to update user details.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleFileChange = (e) => {
    setDp(e.target.files[0]);
  };
  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal
        isCentered
        isOpen={isOpen}
        size={"xl"}
        scrollBehavior={"inside"}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box maxW="sm" mx="auto" mt={5}>
              <form onSubmit={handleSubmit}>
                <FormControl id="countryCode">
                  <FormLabel>Country Code</FormLabel>
                  <Input
                    type="text"
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                    placeholder="Enter country code"
                  />
                </FormControl>

                <FormControl id="phoneNo" mt={4}>
                  <FormLabel>Phone Number</FormLabel>
                  <Input
                    type="text"
                    value={phoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                    placeholder="Enter phone number"
                  />
                </FormControl>

                <FormControl id="whatsAppNo" mt={4}>
                  <FormLabel>WhatsApp Number</FormLabel>
                  <Input
                    type="text"
                    value={whatsAppNo}
                    onChange={(e) => setWhatsAppNo(e.target.value)}
                    placeholder="Enter WhatsApp number"
                  />
                </FormControl>

                <FormControl id="name" mt={4}>
                  <FormLabel>Name</FormLabel>
                  <Input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter name"
                  />
                </FormControl>

                <FormControl id="email" mt={4}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter email"
                  />
                </FormControl>

                <FormControl id="password" mt={4}>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter password"
                  />
                </FormControl>

                <FormControl id="dp" mt={4}>
                  <FormLabel>Profile Picture</FormLabel>
                  <Input type="file" onChange={handleFileChange} />
                </FormControl>

                <Button mt={4} colorScheme="teal" type="submit">
                  Update Details
                </Button>
              </form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
