import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import styles from "../../Styles";
import { RecruimentHeader } from "../component/RecruimentHeader";
import { FaPhone } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { useCandidate } from "../../context/CandidateContext";
import { useEnv } from "../../context/EnvContext";
import { useAuth } from "../../context/AuthContext";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Td,
  Tr,
  Table,
  Thead,
  Th,
  Tbody,
  Link,
  Avatar,
  Box,
  Text,
} from "@chakra-ui/react";
import { PiDotsThreeOutlineBold } from "react-icons/pi";
import CandidateModal from "../component/modal/CandidateModal";
import { useJd } from "../../context/JobDescriptContext";
import { compareDataArrays } from "../../Utils/globalFunction";
import AdminSubheader from "../../admin/components/AdminSubheader";
import Header from "../../admin/components/Header";
import UpdateCandidateModal from "../component/modal/UpdateCandidateModal";
import { DeleteCandidateContext } from "../../context/DeleteCandidateContext";
import { formatDate } from "../../Utils/ExtraFun";
import _ from "lodash";

export const Candidate = () => {
  const { getAllCandidate, allCandidates, getCandidate } = useCandidate();
  const [candidateData, setCandidateData] = useState([]);
  const { handleDeleteCandidate } = useContext(DeleteCandidateContext);
  const { backendUrl } = useEnv();
  const { userData } = useAuth();
  const [filteredData, setFilteredData] = useState([]);
  const [jdId, setJdId] = useState(null);
  const [candidateId, setCandidateId] = useState([]);

  const [selectedJd, setSelectedJd] = useState(null);
  // const [similarityPercentage, setSimilarityPercentage] = useState(0);

  const { getAllJd, allJds, getJd, handleApplicationForm } = useJd();

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (userData?.token && backendUrl) {
      console.log("get candidate is calling..");
      getAllCandidate();
    }
  }, [backendUrl, userData?.token, getAllCandidate]);

  useEffect(() => {
    console.log("All Candidate:", allCandidates);
  }, [allCandidates]);

  useEffect(() => {
    if (userData?.token) {
      getAllJd();
    }
  }, [userData]);

  useEffect(() => {
    if (allJds) {
      setFilteredData(allJds);
    }
  }, [allJds]);
  useEffect(() => {
    setCandidateData(allCandidates);
  }, [allCandidates]);

  const handleCompareData = (data2) => {
    const data1 = filteredData.map((item) => item);
    const percentage = compareDataArrays(data1, data2);
    // setSimilarityPercentage(percentage.toFixed(2));
  };

  const handleChange = (e) => {
    const selectedId = e.target.value;
    console.log("selectedId", selectedId);
    setJdId(selectedId);

    const selectedData = allJds.find((jd) => jd.jobId === selectedId);
    setSelectedJd(selectedData);

    handleCompareData(selectedData ? [selectedData] : []);
  };

  useEffect(() => {
    if (selectedJd) {
      handleCompareData([selectedJd]);
    }
  }, [selectedJd]);

  //logic for serach query
  const handleSearch = (searchQuery) => {
    if (Array.isArray(allCandidates)) {
      const lowerCaseQuery = searchQuery?.toLowerCase();
      const newFilteredData = allCandidates.filter((item) => {
        const fields = [
          item?.applicationID,
          item?.applicationStatus,
          item?.jobDescription?.jobId,
          item?.recruiter?.empId,
          item?.candidate?.stuId,
          item?.candidate?.email,
          item?.candidate?.name,
          item?.candidate?.phone,
          item?.currentState,
          item?.cvProcess,
          item?.cvSelectionDetails?.processId,
          item?.placementDetails?.PlacementId,
          item?.placementProcess,
          item?.stuId,
          item?.addedBy?.name,
          item?.name,
        ];

        return fields.some((field) =>
          (field?.toLowerCase() || "").includes(lowerCaseQuery)
        );
      });

      setCandidateData(newFilteredData);
    } else if (searchQuery.trim() === "") {
      setCandidateData(allCandidates);
    } else {
      console.warn(
        "fetchedData is not an array or is undefined",
        allCandidates
      );
    }
  };

  const debouncedHandleSearch = useCallback(
    _.debounce((query) => handleSearch(query), 300),
    [allCandidates]
  );

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const onSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedHandleSearch(query);
  };

  const toggleCandidateSelection = (id) => {
    console.log("Current candidateId state:", candidateId);

    if (!Array.isArray(candidateId)) {
      console.error("candidateId is not an array:", candidateId);
      setCandidateId([]);
      return;
    }

    setCandidateId((prev) => {
      console.log("Previous state:", prev);
      const newState = prev.includes(id)
        ? prev.filter((candidateId) => candidateId !== id)
        : [...prev, id];
      console.log("new state:", newState);
      return newState;
    });
  };

  const addCandidateToRequirement = () => {
    if (jdId && candidateId.length > 0) {
      handleApplicationForm(jdId, candidateId);
    } else {
      alert("Please select at least one candidate and a requirement.");
    }
  };

  return (
    <Fragment>
      {userData.userType === "Recruiter" ? <RecruimentHeader /> : <Header />}
      <AdminSubheader />
      <div className={`bg-black overflow-hidden w-full h-fit pipeline`}>
        <div className={`${styles.paddingX} ${styles.flexCenter} z-[3]`}>
          <div
            className={`${styles.boxWidth} items-center justify-items-center`}
          >
            <h1 className="font-poppins text-blue-500 text-4xl shadow-lg shadow-blue-500 mt-3 text-center">
              Candidates
            </h1>
            {userData.userType === "Recruiter" && (
              <div
                className={`mt-[25px] font-poppins font-normal cursor-pointer text-center flex flex-row justify-around`}
              >
                <div
                  className={`flex bg-blue-900 flex-wrap cursor-pointer shadow-lg shadow-blue-500 border-2 rounded-2xl h-[110px] w-[500px]`}
                >
                  <label
                    className={`mt-1 mx-3 text-white font-poppins text-[16px] underline my-[5px]`}
                    htmlFor="requirements"
                  >
                    My Active Requirements
                  </label>
                  <div className="flex flex-col w-full justify-center items-center">
                    <select
                      className="ml-5 w-[90%]"
                      name="requirements"
                      id="requirements"
                      onChange={handleChange}
                    >
                      <option value="">Select a Requirement</option>
                      {filteredData?.map((data, index) => (
                        <option key={index} value={data._id}>
                          Job Id: {data.jobId}, {data.jobTitle},{" "}
                          {data.companyDetails.companyName}
                        </option>
                      ))}
                    </select>

                    <button
                      onClick={addCandidateToRequirement}
                      className="w-[full] justify-center text-center flex flex-wrap border-[0.5px] mt-[10px] rounded-2xl"
                    >
                      <Link
                        color={"white"}
                        className={`text-white w-[280px] h-[25px] rounded-2xl`}
                        to="#"
                      >
                        Add Candidate to Requirement
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="flex h-[60px] w-[full] justify-center border-2 bg-blue-900 shadow-lg shadow-blue-500 mt-[60px]">
              <div className="input-group mx-2 flex flex-1 w-[400px] mb-4 space-x-1.5">
                <label
                  className="mt-1 text-white font-poppins text-[16px] mr-[10px]"
                  htmlFor="search"
                >
                  Search Name
                </label>
                <input
                  type="search"
                  className="form-control  relative flex min-w-0 mt-[10px] w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="button-addon3"
                  onChange={onSearchChange}
                  onKeyDown={handleKeyPress}
                />
                <button
                  className="mt-2 btn px-5 py-2 border-2 border-white text-white font-poppins text-xs uppercase rounded-xl hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                  type="button"
                  id="button-addon3"
                  onClick={() => handleSearch(searchQuery)}
                >
                  Search
                </button>
              </div>
            </div>
            <div
              className={`text-white font-poppins text-[16px] border-2 mt-5 h-fit mb-10 bg-blue-900 shadow-lg overflow-scroll shadow-blue-500 items-center w-full max-h-[80vh] `}
              style={{
                scrollbarWidth: "thin",
              }}
            >
              {/*firefox scrollbar styling*/}
              <style>
                {`.text-white::-webkit-scrollbar{
                  width:12px;}
                  .text-white::-webkit-scrollbar-thumb{
                  background-color:blue;
                  border-radius:10px;
                  }
                  .text-white::-webkit-scrollbar-track{
                  background:#ffffff00}`}
              </style>
              {/* <TableContainer
                style={{
                  scrollbarWidth: "thin",
                }}
              > */}
              <Table
                className="table w-full  text-center z-[1] "
                id="table-to-xls"
              >
                <Thead
                  className="sticky top-0 bg-blue-900 border-white z-10"
                  border={"1px"}
                  borderColor={"white"}
                >
                  <Tr>
                    <Th
                      className="py-2 px-4 border-b border-white  "
                      color={"white"}
                      border={"2px"}
                      borderColor={"white"}
                    >
                      Sl No
                    </Th>
                    {userData.userType === "Recruiter" && (
                      <Th
                        className="py-2 px-4 border-b border-white"
                        color={"white"}
                        border={"2px"}
                        borderColor={"white"}
                      >
                        Select
                      </Th>
                    )}
                    <Th
                      className="py-2 px-4 border-b border-white"
                      color={"white"}
                      border={"2px"}
                      borderColor={"white"}
                    >
                      Candidate Id
                    </Th>
                    {userData.userType !== "Recruiter" && (
                      <Th
                        className="py-2 px-4 border-white"
                        color={"white"}
                        border={"2px"}
                        borderColor={"white"}
                      >
                        Recruiter
                      </Th>
                    )}
                    <Th
                      className="py-2 px-4 border-b border-white"
                      color={"white"}
                      border={"2px"}
                      borderColor={"white"}
                    >
                      Name
                    </Th>

                    <Th
                      className="py-2 px-4 border-b border-white"
                      color={"white"}
                      border={"2px"}
                      borderColor={"white"}
                    >
                      Email
                    </Th>
                    <Th
                      className="py-2 px-4 border-b border-white"
                      color={"white"}
                      border={"2px"}
                      borderColor={"white"}
                    >
                      Phone
                    </Th>
                    <Th
                      className="py-2 px-4 border-b border-white"
                      color={"white"}
                      border={"2px"}
                      borderColor={"white"}
                    >
                      Skills
                    </Th>
                    <Th
                      className="py-2 px-4 border-b border-white"
                      color={"white"}
                      border={"2px"}
                      borderColor={"white"}
                    >
                      Experience
                    </Th>
                    <Th
                      className="py-2 px-4 border-b border-white"
                      color={"white"}
                      border={"2px"}
                      borderColor={"white"}
                    >
                      Previous Company
                    </Th>
                    <Th
                      className="py-2 px-4 border-b border-white"
                      color={"white"}
                      border={"2px"}
                      borderColor={"white"}
                    >
                      Current Status
                    </Th>

                    <Th
                      className="py-2 px-2 border-b border-white"
                      color={"white"}
                      border={"2px"}
                      borderColor={"white"}
                    >
                      Created Date
                    </Th>

                    <Th
                      className="py-2 px-4 border-b border-white"
                      color={"white"}
                      border={"2px"}
                      borderColor={"white"}
                    >
                      More
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {candidateData?.map((candidate, index) => (
                    <Tr key={index} className="text-center">
                      <Td className="py-2 px-4 border-b">{index + 1}</Td>

                      {userData.userType === "Recruiter" && (
                        <Td>
                          <input
                            value={candidate?._id}
                            type="checkbox"
                            className="mx-2"
                            name="selectedCandidate"
                            checked={candidateId.includes(candidate?._id)}
                            onChange={() =>
                              toggleCandidateSelection(candidate?._id)
                            }
                          />
                        </Td>
                      )}
                      <td className="py-2 px-4 border-b">{candidate?.stuId}</td>
                      {userData.userType !== "Recruiter" && (
                        <Td className="py-2 px-4 border-b">
                          <Text> {candidate?.addedBy?.name}</Text>
                        </Td>
                      )}

                      <Td className="py-2 px-4 border-b">
                        <CandidateModal>
                          <div
                            className="flex flex-row items-center gap-2 cursor-pointer"
                            onClick={() => getCandidate(candidate?._id)}
                          >
                            <Avatar
                              src={backendUrl + candidate?.dp}
                              name={`${candidate?.name}`}
                              size="sm"
                            />
                            <Text as={"u"}> {candidate?.name}</Text>
                          </div>
                        </CandidateModal>
                      </Td>

                      <Td className="py-2 px-4 border-b">{candidate?.email}</Td>
                      <Td className="py-2 px-4 border-b">
                        <div className="flex flex-col">
                          <div>
                            <FaPhone className="inline-block mr-2 text-blue-500" />
                            {candidate?.phone}
                          </div>
                          <div>
                            <a
                              href={`https://wa.me/${candidate?.whatsappNo}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="flex items-center justify-center"
                            >
                              <FaWhatsapp className="inline-block mr-2 text-green-500" />
                              {candidate?.whatsappNo}
                            </a>
                          </div>
                        </div>
                      </Td>
                      <Td className="py-2 px-4 border-b">
                        <Box
                          overflowY={"scroll"}
                          maxH={"5rem"}
                          overflowX={"hidden"}
                        >
                          {(candidate?.skills ?? []).join(", ")}
                        </Box>
                      </Td>
                      <Td className="py-2 px-4 border-b">
                        {candidate?.isFresher
                          ? "Fresher"
                          : `${candidate?.experience} years (${candidate?.experienceType})`}
                      </Td>
                      <Td className="py-2 px-4 border-b">
                        {candidate?.previousCompany?.companyName}
                      </Td>
                      <Td className="py-2 px-4 border-b">
                        {candidate?.currentStatus}
                      </Td>
                      <Td className="py-2 px-2 border-b">
                        {formatDate(candidate?.createdAt)}
                      </Td>
                      <Td>
                        <Menu>
                          <MenuButton
                            alignItems={"center"}
                            as={Button}
                            rightIcon={<PiDotsThreeOutlineBold />}
                          ></MenuButton>
                          <MenuList backgroundColor={"black"}>
                            <MenuItem
                              backgroundColor={"black"}
                              textColor={"white"}
                              _hover={{ backgroundColor: "blue.500" }}
                            >
                              <Link
                                href={backendUrl + candidate?.cv}
                                isExternal
                                rel="noopener noreferrer"
                              >
                                View CV
                              </Link>
                            </MenuItem>
                            <CandidateModal>
                              <MenuItem
                                backgroundColor={"black"}
                                _hover={{ backgroundColor: "blue.500" }}
                                onClick={() => getCandidate(candidate?._id)}
                              >
                                view Details
                              </MenuItem>
                            </CandidateModal>
                            <UpdateCandidateModal candidateDetails={candidate}>
                              <MenuItem
                                backgroundColor={"black"}
                                _hover={{ backgroundColor: "blue.500" }}
                                onClick={() => console.log(candidate?._id)}
                              >
                                Update Candidate
                              </MenuItem>
                            </UpdateCandidateModal>
                            {userData?.userType === "Recruiter" && (
                              <MenuItem
                                bg={"black"}
                                _hover={{ backgroundColor: "blue.500" }}
                                onClick={() =>
                                  handleDeleteCandidate(candidate._id)
                                }
                              >
                                Delete Candidate
                              </MenuItem>
                            )}
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              {/* </TableContainer> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
