import React, { Fragment } from 'react'
import { Link } from "react-router-dom";
import styles from '../../Styles';
import { RecruimentHeader } from '../component/RecruimentHeader';
// import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';

export const NewCandidate = () => {
    return (
        <Fragment>
            <RecruimentHeader />
            <div className={` bg-black overflow-hidden w-full  h-[1200px] pipeline`}>
                <div className={`${styles.paddingX} ${styles.flexCenter} z-[3]`}>
                    <div className={`${styles.boxWidth} text-white`}>

                        <h1 className='font-poppins text-blue-500 text-4xl shadow-lg shadow-blue-500 mt-3 text-center'>New Candidate</h1>
                        <div className={`text-white font-poppins text-[16px] border-2 mt-10 h-[500px] bg-blue-900 shadow-lg shadow-blue-500  items-center overflow-hidden ${styles.boxWidth}`}>
                            {/* <Form.Group controlId="formFile" className="mt-2">
                            <Form.Label className={`text-[20px]`}>Click On 'Choose File' button to select Candidate cv</Form.Label> <br />
                            <Form.Control className={`mt-[5px]`} type="file" />
                        </Form.Group> <br /> */}
                            <div className="upload-btn-wrapper">
                                <button className="btn">Upload file</button>
                                <input type="file" name="myfile" />
                            </div>

                            <div className={`bg-blue-500 text-center w-[250px] items-center mx-[518px] border-[1px] rounded-[5px] text-[18px]`}>
                                <Link to="/CandidateDetails1">Run resume-parser </Link>{' '}
                            </div>
                            <p className={`mx-[330px] mt-6 text-[16px] text-red-500`}>(Please be patient .Resume Parser may take upto 60seconds to process CV.)</p>
                            <p className={`mx-[575px] mt-4 text-[16px] text-white`}>- - - -OR- - - -</p>
                            <div className={`bg-blue-500 text-center w-[250px] items-center mt-5 mx-[518px] border-[1px] rounded-[5px] text-[18px] `}>
                                <Link to="/new_candidate/form">New Candidate Form
                                </Link>{' '}
                            </div>
                        </div>

                    </div>
                </div>

                <div className="relative z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
                <div className="relative z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-10" />
                <div className="relative z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
            </div >
        </Fragment>
    )
}
