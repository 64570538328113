import React, { Children, useContext } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Button,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  ChakraProvider,
  Box,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { usePipeLine } from "../../../context/PipelineContext";
const RecordCvPop = ({ children, applicationID }) => {
  const { recordCvRes } = usePipeLine();
  return (
    <Popover>
      <PopoverTrigger>
        <span>{children}</span>
      </PopoverTrigger>
      <PopoverContent color="black">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Submit Remark</PopoverHeader>
        <PopoverBody>
          <ChakraProvider>
            <Box maxW="md" mx="auto" mt="10">
              <Formik
                initialValues={{ result: "", remark: "" }}
                onSubmit={(values) => {
                  // alert(JSON.stringify(values, null, 2));
                  console.log("values", values);
                  console.log("applicationIDpop", applicationID);
                  const postData = {
                    result: values.result,
                    remark: values.remark,
                  };
                  recordCvRes(applicationID, postData);
                }}
              >
                {({ handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <Field name="result">
                      {({ field }) => (
                        <FormControl id="result" mb="4">
                          <FormLabel>Result</FormLabel>
                          <Select {...field} placeholder="Select option">
                            <option value="Rejected">Rejected</option>
                            <option value="Selected">Selected</option>
                          </Select>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="remark">
                      {({ field }) => (
                        <FormControl id="remark" mb="4">
                          <FormLabel>Remark</FormLabel>
                          <Textarea
                            {...field}
                            placeholder="Enter your remark"
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Button type="submit" colorScheme="blue">
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </Box>
          </ChakraProvider>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default RecordCvPop;
