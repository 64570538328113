import React, { createContext, useCallback, useMemo } from "react";
import { useEnv } from "./EnvContext";
import { useAuth } from "./AuthContext";
import toast from "react-hot-toast";
import axios from "axios";
import { useCandidate } from "./CandidateContext";

export const DeleteCandidateContext = createContext();
const DeleteCandidateContextProvider = ({ children }) => {
  
  const { getAllCandidate} = useCandidate();
  const { backendUrl } = useEnv();
  const { userData } = useAuth();
 
  const handleDeleteCandidate = useCallback(
    async (candidateId) => {
      const confirm = window.confirm("Are you sure to delete?");
      if (!confirm) return;
      const token = userData?.token;
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      let loadingtoast;
      try {
        loadingtoast = toast.loading("Delete Candidate....", {
          position: "top-right",
          style: {
            marginTop: "62px",
          },
        });

        const response = await axios.delete(
          `${backendUrl}/candidate?candidateId=${candidateId}`,
          { headers }
        );
        console.log("Response:", response);
        const data = response.data;
        getAllCandidate();
        console.log("response data:", data);

        toast.success(data.message, {
          position: "top-right",
          style: {
            marginTop: "65px",
          },
        });
      } catch (error) {
        console.log("error:", error);
        console.log("error response:", error.response);
        if (error.response.data.message) {
          toast.error(`${error.response.data.message}`, {
            position: "top-right",
            style: {
              marginTop: "65px",
            },
          });
        } else {
          toast.error("Something Went Wrong", {
            position: "top-right",
            style: {
              marginTop: "70px",
            },
          });
        }
      } finally {
        if (loadingtoast) {
          toast.dismiss(loadingtoast);
        }
      }
    },
    [backendUrl, userData?.token]
  );

  const contextValue = useMemo(
    () => ({
      handleDeleteCandidate,
    }),
    [handleDeleteCandidate]
  );

  return (
    <DeleteCandidateContext.Provider value={contextValue}>
      {children}
    </DeleteCandidateContext.Provider>
  );
};

export default DeleteCandidateContextProvider;
