import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Button,
  ListItem,
  Link,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useCandidate } from "../../../context/CandidateContext";
import { useEnv } from "../../../context/EnvContext";
import { NewCandidateForm } from "../../pages";

function UpdateCandidateModal({ children, candidateDetails }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { candidateData } = useCandidate();
  const { backendUrl } = useEnv();
  // useEffect(() => {
  //   if (candidateData) {
  //     console.log("candidateData", candidateData);
  //   }
  // }, [candidateData]);

  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        size={"full"}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Candidate</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <NewCandidateForm
              candidateDetails={candidateDetails}
              donotShow={true}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default UpdateCandidateModal;
