import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import EnvContext from "../../../context/EnvContext";
import JobDescription from "../../forms/JobDescription";

export const UpdateJDModal = ({ children, jdDetails }) => {
  const { backendUrl } = useContext(EnvContext);
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal
        isCentered
        isOpen={isOpen}
        size={"full"}
        scrollBehavior={"inside"}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update JD Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <JobDescription dontShow={true} jdDetails={jdDetails} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
