import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

export default function AdminSubheader() {
  const inActiveCSS =
    "hover:text-base hover:font-bold font-poppins font-normal cursor-pointer text-[16px] text-white";
  const activeCSS =
    "bg-white border-2 px-2 border-solid rounded-md text-base font-bold font-poppins cursor-pointer text-gray-600";

  const { userData } = useAuth();
  const [path, setPath] = useState("");

  useEffect(() => {
    if (!userData) return;
    console.log(userData);
    userData &&
      setPath(
        {
          Admin: "admin",
          "HR Manager": "hr",
          "Business Developer": "bde",
          Recruiter: "rec",
        }[userData.userType]
      );
  }, [userData]);

  useEffect(() => {
    console.log(path);
  }, [path]);
  return (
    <div className=" h-[50px] bg-black ">
      <div
        className={`border-2 w-[full] mx-4 h-[37px] bg-blue-900 shadow-lg shadow-blue-500`}
      >
        <ul
          className={`list-none md:flex justify-between items-center flex-1 px-1 mx-2 my-1`}
        >
          {path !== "rec" && (
            <li className="nav-item text-white  ">
              <NavLink
                className={({ isActive }) =>
                  isActive ? activeCSS : inActiveCSS
                }
                aria-current="page"
                to={`/${path}/employee`}
              >
                Employee
              </NavLink>
            </li>
          )}

          <li className="nav-item text-white  ">
            <NavLink
              className={({ isActive }) => (isActive ? activeCSS : inActiveCSS)}
              aria-current="page"
              to={`/${path}/candidate`}
            >
              Candidate
            </NavLink>
          </li>

          <li className="nav-item ">
            <NavLink
              className={({ isActive }) => (isActive ? activeCSS : inActiveCSS)}
              to={`/${path}/requirement`}
            >
              Requirements
            </NavLink>
          </li>
          <li className="nav-item]">
            <NavLink
              className={({ isActive }) => (isActive ? activeCSS : inActiveCSS)}
              to={`/${path}/pipelines`}
            >
              Pipelines
            </NavLink>
          </li>
          <li className="nav-item ">
            <NavLink
              className={({ isActive }) => (isActive ? activeCSS : inActiveCSS)}
              to={`/${path}/interviews`}
            >
              Interviews
            </NavLink>
          </li>
          <li className="nav-item  ">
            <NavLink
              className={({ isActive }) => (isActive ? activeCSS : inActiveCSS)}
              to={`/${path}/offers`}
            >
              Offers
            </NavLink>
          </li>
          <li className="nav-item  ">
            <NavLink
              className={({ isActive }) => (isActive ? activeCSS : inActiveCSS)}
              to={`/${path}/placements`}
            >
              Placements
            </NavLink>
          </li>
          <li className="nav-item ">
            <NavLink
              className={({ isActive }) => (isActive ? activeCSS : inActiveCSS)}
              to={`/${path}/feeshare`}
            >
              FeeShare
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}
