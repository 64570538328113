
import { Toaster } from 'react-hot-toast';
import './App.css';
import { AllRoutes } from './Routes/AllRoutes';
import { useAuth } from './context/AuthContext';
import { useEffect } from 'react';


function App() {
  localStorage.removeItem('chakra-ui-color-mode');

  // console.log("process.env.REACT_APP_BACKEND_URL",process.env.REACT_APP_BACKEND_URL)
  const { setUserData } = useAuth()
  const storedUserData = localStorage.getItem('userData');
  useEffect(() => {
    if (!storedUserData) return;

    if (storedUserData) { setUserData(JSON.parse(storedUserData)); }
  }, [storedUserData]);


  return (
    <div>
      <AllRoutes />

      <Toaster containerStyle={{ marginTop: "5rem" }} />
    </div>
  );
}

export default App;
