import React from "react";
import { Route, Routes } from "react-router-dom";

import {
  Candidate,
  FeesShare,
  Interviews,
  NewCandidate,
  NewCandidateForm,
  Offers,
  Pipelines,
  Placements,
  Requirement,
} from "../recruiter/pages";
import {
  AdminCandudate,
  AdminFeesShare,
  AdminInterview,
  AdminOffers,
  AdminPipelines,
  AdminPlacements,
  AdminRequirement,
} from "../admin/pages";
import { UserIdGenerator } from "../admin/UserIdGenerator";
import { HomePage } from "../pages/HomePage";
import RunResumeParser from "../recruiter/pages/RunResumeParser";
import JobDescription from "../admin/forms/JobDescription";
import AdminEmployee from "../admin/pages/AdminEmployee";

// import EditForm from '../admin/forms/EditForm'

export const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" Component={HomePage} />
      {/* for admin related  */}
      <Route path="/admin/candidate" Component={Candidate}></Route>
      <Route path="/admin/requirement" Component={Requirement}></Route>
      <Route path="/admin/pipelines" Component={Pipelines}></Route>
      <Route path="/admin/interviews" Component={Interviews}></Route>
      <Route path="/admin/offers" Component={Offers}></Route>
      <Route path="/admin/placements" Component={Placements}></Route>
      <Route path="/admin/feeshare" Component={FeesShare}></Route>
      <Route
        path="/admin/user_id_generator"
        Component={UserIdGenerator}
      ></Route>
      <Route path="/admin/job_description" Component={JobDescription}></Route>
      <Route path="/admin/employee" Component={AdminEmployee}></Route>

      {/* <Route path='/editform' Component={EditForm}></Route> */}

      {/* for HR manager  related ... */}
      <Route path="/hr/employee" Component={AdminEmployee}></Route>
      <Route path="/hr/candidate" Component={Candidate}></Route>
      <Route path="/hr/requirement" Component={Requirement}></Route>
      <Route path="/hr/pipelines" Component={Pipelines}></Route>
      <Route path="/hr/interviews" Component={Interviews}></Route>
      <Route path="/hr/offers" Component={Offers}></Route>
      <Route path="/hr/placements" Component={Placements}></Route>
      <Route path="/hr/feeshare" Component={FeesShare}></Route>

      {/* for business developer  related ... */}
      <Route path="/bde/employee" Component={AdminEmployee}></Route>
      <Route path="/bde/candidate" Component={Candidate}></Route>
      <Route path="/bde/requirement" Component={Requirement}></Route>
      <Route path="/bde/pipelines" Component={Pipelines}></Route>
      <Route path="/bde/interviews" Component={Interviews}></Route>
      <Route path="/bde/offers" Component={Offers}></Route>
      <Route path="/bde/placements" Component={Placements}></Route>
      <Route path="/bde/feeshare" Component={FeesShare}></Route>

      {/* for recruiter related .... */}
      <Route path="/candidate" Component={Candidate}></Route>
      <Route path="/requirement" Component={Requirement}></Route>
      <Route path="/pipelines" Component={Pipelines}></Route>
      <Route path="/interviews" Component={Interviews}></Route>
      <Route path="/offers" Component={Offers}></Route>
      <Route path="/placements" Component={Placements}></Route>
      <Route path="/feeshare" Component={FeesShare}></Route>

      <Route path="/new_candidate" Component={NewCandidate}></Route>
      <Route path="/new_candidate/form" Component={NewCandidateForm}></Route>
      <Route path="/CandidateDetails1" Component={RunResumeParser}></Route>

      {/* for final recruiter related .... */}
      <Route path="/rec/candidate" Component={Candidate}></Route>
      <Route path="/rec/requirement" Component={Requirement}></Route>
      <Route path="/rec/pipelines" Component={Pipelines}></Route>
      <Route path="/rec/interviews" Component={Interviews}></Route>
      <Route path="/rec/offers" Component={Offers}></Route>
      <Route path="/rec/placements" Component={Placements}></Route>
      <Route path="/rec/feeshare" Component={FeesShare}></Route>

      <Route path="/rec/new_candidate" Component={NewCandidate}></Route>
      <Route
        path="/rec/new_candidate/form"
        Component={NewCandidateForm}
      ></Route>
      <Route path="/rec/CandidateDetails1" Component={RunResumeParser}></Route>
      {/* for HR related ... */}
      <Route path="/hr/candidate" Component={AdminCandudate}></Route>
    </Routes>
  );
};
